import { useEffect } from 'react';
import { Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from "react-router-dom";

import FilterableTable from 'components/Admin/ObjectTable';

import { fetchPrioritizedActivities } from 'Redux/Admin/TaskManager/PrioritizedActivities/action';

import rootReducer from 'Redux/root-reducer';

import type { TableColumnsType } from 'antd';
import type { FilterConfig } from '@types';
import type { IPrioritizedActivity } from 'Redux/Admin/TaskManager/PrioritizedActivities/type';

import { ADMIN_PATH } from "utils/routes";

type RootState = ReturnType<typeof rootReducer>;

const columns: TableColumnsType<IPrioritizedActivity> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 120,
    render: (_, record) => (
      <Space size="middle">
        <Link to={`${ADMIN_PATH}/task-manager/prioritized-activities/${record.id}`}>{record.id}</Link>
      </Space>
    ),
  },
  {
    title: 'Direct Impact',
    dataIndex: 'direct_impact',
    key: 'direct_impact',
    width: 80,
  },
  {
    title: 'Indirect Impact',
    dataIndex: 'indirect_impact',
    key: 'indirect_impact',
    width: 80,
  },
  {
    title: 'Goal Weight',
    dataIndex: 'goal_weight',
    key: 'goal_weight',
    width: 80,
  },
  {
    title: 'Activity Weight',
    dataIndex: 'activity_weight',
    key: 'activity_weight',
    width: 80,
  },
  {
    title: 'Calculated Priority',
    dataIndex: 'calculated_priority',
    key: 'calculated_priority',
    width: 80,
  },
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
    width: 120,
  },
];

const PrioritizedActivities = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const user = params.get('user');
  const searchValue = params.get('search');
  const pageValue = parseInt(params.get('page') || '1');

  const { entities, ids, count, loading } = useSelector((state: RootState) => state.fetchPrioritizedActivities);

  useEffect(() => {
    dispatch(fetchPrioritizedActivities({ user, search: searchValue, offset: (pageValue - 1) * 50, limit: 50 }, () => {}));
  }, [user, searchValue, pageValue, dispatch]);

  // Build filter configs for this page
  const filterConfigs: FilterConfig[] = [
    {
      type: 'search',
      paramName: 'search',
      label: 'Search',
      placeholder: 'Search...'
    },
    {
      type: 'user-select',
      paramName: 'user',
      placeholder: 'Select user...',
      label: 'User'
    },
  ];

  const dataItems = ids.map(id => entities[id]);

  return (
    <FilterableTable<IPrioritizedActivity>
      columns={columns}
      data={dataItems}
      loading={loading}
      totalItems={count}
      filters={filterConfigs}
    />
  );
}

export default PrioritizedActivities;
