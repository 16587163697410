import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { GoogleOAuthProvider } from "@react-oauth/google";

import theme from "./theme";
import AppRoutes from "./appRoutes";

import store from "Redux/store";

import GTMLoader from "components/GTMLoader";

import "./App.css";
import "./styles.css";

import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <>
      <Provider store={store}>
        <GoogleOAuthProvider clientId="207150144695-s8f0j0lsk6oun0hgvh7dndebjgvu831n.apps.googleusercontent.com">
          <BrowserRouter>
            <ThemeProvider theme={theme}>
              <DndProvider backend={HTML5Backend}>
                <GTMLoader />
                <AppRoutes />
              </DndProvider>
            </ThemeProvider>
          </BrowserRouter>
        </GoogleOAuthProvider>
        <ToastContainer />
      </Provider>
    </>
  );
}

export default App;
