import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash/debounce";
import { Select, Spin } from "antd";

import type { SelectProps } from "antd";
import type { DefaultOptionType } from 'antd/es/select';

import { RootState } from "Redux/store";
import { fetchUsers } from "Redux/Admin/Users/action";

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, "options" | "children"> {
  /** optional debouncing time for search input */
  debounceTimeout?: number;
}

/**
 * A debounced user-select component that fetches user data 
 * from the server whenever the user types in the search box.
 */
function UserSelect<
  ValueType extends {
    key?: string;
    label: React.ReactNode;
    value: string | number;
  } = any,
>({
  debounceTimeout = 800,
  onChange,
  ...props
}: DebounceSelectProps<ValueType>) {
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state: RootState) => state.fetchUsersReducer);

  // Dispatch an action on first render to load initial data
  useEffect(() => {
    dispatch(fetchUsers({ search: "", offset: 0, limit: 50 }, () => {}));
  }, [dispatch]);

  const handleChange = (value: ValueType | ValueType[], option?: DefaultOptionType | DefaultOptionType[]) => {
    if (onChange) {
      onChange(value, option);
    }
  }

  // Create a debounced version of the search handler
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      dispatch(fetchUsers({ search: value, offset: 0, limit: 50 }, () => {}));
    };
    return debounce(loadOptions, debounceTimeout);
  }, [debounceTimeout, dispatch]);

  // Transform your store's user data into AntD <Select> options
  const usersData = data.results
    ?.filter((user: any) => user.external_accounts[0]?.goal_setting_workbook)
    .map((user: any) => ({
      label: user.email,
      value: user.external_accounts[0].goal_setting_workbook,
    })) || [];

  return (
    <Select
      // Spread any "leftover" props you might want to pass 
      // (e.g., value, onChange, mode, etc.)
      {...props}
      showSearch
      loading={loading}
      options={usersData}
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={loading ? <Spin size="small" /> : null}
      labelInValue
      onChange={handleChange}
      value={props.value || undefined}
    />
  );
}

export default UserSelect;
