import Notify from "utils/notify";

import {
  adminFetchProjectPlans,
  adminFetchProjectPlan,
  adminCreateProjectPlan,
  adminUpdateProjectPlan,
  adminDeleteProjectPlan
} from "api";

import { ActionTypes, IProjectPlan } from "./type";

export const fetchProjectPlans = (payload: any, callback?: () => any) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.REQUEST });

    const { data } = await adminFetchProjectPlans(payload);

    if (Array.isArray(data.results)) {
      dispatch({
        type: ActionTypes.SUCCESS,
        payload: data
      });
    }
    else {
      dispatch({
        type: ActionTypes.FAILURE,
        payload: data
      });

      Notify.error(data, true);
    }

    if (callback)
      callback();
  } catch (error) {
    console.error(error);

    dispatch({
      type: ActionTypes.FAILURE,
      payload: error
    });

    Notify.error(error, true);
  }
};

export const fetchProjectPlan = (payload: any, callback?: () => any) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.REQUEST_SINGLE });

    const { data } = await adminFetchProjectPlan(payload);

    dispatch({
      type: ActionTypes.SUCCESS_SINGLE,
      payload: data
    });

    if (callback)
      callback();
  } catch (error) {
    console.error(error);

    dispatch({
      type: ActionTypes.FAILURE,
      payload: error
    });

    Notify.error(error, true);
  }
};

export const createProjectPlan = (payload: any, callback?: (data: IProjectPlan) => any) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.CREATE_REQUEST });

    const { data } = await adminCreateProjectPlan(payload);

    dispatch({
      type: ActionTypes.CREATE_SUCCESS,
      payload: data
    });

    Notify.success('Project Plan created successfully!');

    if (callback)
      callback(data);
  } catch (error: any) {
    console.error(error);

    dispatch({
      type: ActionTypes.FAILURE,
      payload: error?.response?.data
    });

    Notify.error(error, true);
  }
};

export const updateProjectPlan = (payload: any, id: string, callback?: () => any) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.UPDATE_REQUEST });

    const { data } = await adminUpdateProjectPlan(payload, id);

    dispatch({
      type: ActionTypes.UPDATE_SUCCESS,
      payload: data
    });

    Notify.success('Project Plan updated successfully!');

    if (callback)
      callback();
  } catch (error: any) {
    console.error(error);

    dispatch({
      type: ActionTypes.FAILURE,
      payload: error?.response?.data
    });

    Notify.error(error, true);
  }
};

export const deleteProjectPlan = (id: string, callback?: () => any) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.DELETE_REQUEST });

    await adminDeleteProjectPlan(id);

    dispatch({
      type: ActionTypes.DELETE_SUCCESS,
      payload: id
    });

    Notify.success('Project Plan deleted successfully!');

    if (callback)
      callback();
  } catch (error) {
    console.error(error);

    dispatch({
      type: ActionTypes.FAILURE,
      payload: error
    });

    Notify.error(error, true);
  }
};
