import { combineReducers } from "redux";

// @ts-ignore
import tokenReducer from './Token/reducer';
// @ts-ignore
import registerUserReducer from "./Register/reducer";
// @ts-ignore
import loginUserReducer from "./Login/reducer";
// @ts-ignore
import logoutUserReducer from "./Logout/reducer";

// @ts-ignore
import resetPasswordReducer from "./ResetPasword/reducer";
// @ts-ignore
import resetPasswordLinkReducer from "./PasswordResetLink/reducer";
// @ts-ignore
import verifyOtpReducer from "./VerifyOtp/reducer"
// @ts-ignore
import resendOtpReducer from "./ResendOtp/reducer"
// @ts-ignore
import userDetailReducer from "./User/reducer";

import fetchUsersReducer from "./Admin/Users/reducer";
import fetchUserReducer from "./Admin/User/reducer";
import fetchDmUserReducer from "./Admin/DmUser/reducer";
import fetchGmUserReducer from "./Admin/GmUser/reducer";

import fetchDomains from "./Admin/GoalsManager/Domains/reducer";
import fetchSubdomains from "./Admin/GoalsManager/Subdomains/reducer";

import fetchMasterPlans from "./Admin/GoalsManager/MasterPlans/reducer";
import fetchUserAreas from "./Admin/GoalsManager/UserAreas/reducer";
import fetchUserGoals from "./Admin/GoalsManager/UserGoals/reducer";
import fetchUserActions from "./Admin/GoalsManager/UserActions/reducer";

import fetchProjectUsers from "./Admin/ProjectManager/ProjectUsers/reducer";
import fetchProjectPlans from "./Admin/ProjectManager/ProjectPlans/reducer";
import fetchProjectGoals from "./Admin/ProjectManager/ProjectGoals/reducer";
import fetchActionCategories from "./Admin/ProjectManager/ActionCategories/reducer";
import fetchActionItems from "./Admin/ProjectManager/ActionItems/reducer";

import fetchTasks from "./Admin/TaskManager/Tasks/reducer";
import fetchPrioritizedActivities from "./Admin/TaskManager/PrioritizedActivities/reducer";
import fetchFocusedPriorities from "./Admin/TaskManager/FocusedPriorities/reducer";

import fetchDecisions from "./Admin/DecisionManager/Decisions/reducer";
import fetchOptions from "./Admin/DecisionManager/Options/reducer";
import fetchCriteria from "./Admin/DecisionManager/Criteria/reducer";
import fetchOptionCriteria from "./Admin/DecisionManager/OptionCriteria/reducer";
import fetchPros from "./Admin/DecisionManager/Pros/reducer";
import fetchCons from "./Admin/DecisionManager/Cons/reducer";

export default combineReducers({
  tokenReducer,
  registerUserReducer,
  loginUserReducer,
  logoutUserReducer,

  resetPasswordReducer,
  resetPasswordLinkReducer,

  userDetailReducer,
  verifyOtpReducer,
  resendOtpReducer,

  // Admin
  fetchUsersReducer,
  fetchUserReducer,
  fetchDmUserReducer,
  fetchGmUserReducer,

  fetchDomains,
  fetchSubdomains,

  // Goal Manager
  fetchMasterPlans,
  fetchUserAreas,
  fetchUserGoals,
  fetchUserActions,

  // Project Manager
  fetchProjectUsers,
  fetchProjectPlans,
  fetchProjectGoals,
  fetchActionCategories,
  fetchActionItems,

  // Task Manager
  fetchTasks,
  fetchPrioritizedActivities,
  fetchFocusedPriorities,

  // Decision Manager
  fetchDecisions,
  fetchOptions,
  fetchCriteria,
  fetchOptionCriteria,
  fetchPros,
  fetchCons
});
