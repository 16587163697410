import { normalize, schema } from 'normalizr';
import { IState, ActionTypes } from './type';

const userActionSchema = new schema.Entity('userActions');
const userActionListSchema = [userActionSchema];

const initial: IState = {
  entities: {},
  ids: [],
  count: 0,
  loading: false,
  error: null
};

const reducer = (state: IState = initial, action: any) => {
  switch (action.type) {
    case ActionTypes.REQUEST:
      return {
        ...state,
        loading: true
      };
      
      case ActionTypes.SUCCESS: {
        const normalizedData = normalize(action.payload.results, userActionListSchema);
  
        return {
          ...state,
          entities: {
            ...state.entities,
            ...normalizedData.entities.userActions,
          },
          ids: normalizedData.result as string[],
          count: action.payload.count as number,
          loading: false
        };
      }
  
      case ActionTypes.SUCCESS_SINGLE: {
        const userAction = action.payload;
      
        return {
          ...state,
          entities: {
            ...state.entities,
            [userAction.id]: userAction
          },
          loading: false
        };
      }
  
      case ActionTypes.UPDATE_SUCCESS: {
        const userAction = action.payload;
      
        return {
          ...state,
          entities: {
            ...state.entities,
            [userAction.id]: userAction
          },
          loading: false
        };
      }

      case ActionTypes.DELETE_SUCCESS: {
        const { id } = action.payload;
      
        const { [id]: deletedItem, ...remainingEntities } = state.entities;
      
        return {
          ...state,
          entities: remainingEntities,
          ids: state.ids.filter(existingId => existingId !== id),
          loading: false
        };
      }

    case ActionTypes.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}

export default reducer;
