const sharedSettings = {
  decision: { editable: true, type: 'decision-select', rules: [{ required: true }] },

  title: { editable: true, type: 'input' },
  description: { editable: true, type: 'textarea' },

  weight: { editable: true, type: 'number' },
  priority: { editable: true, type: 'number' },

  rubric: { editable: true, type: 'textarea' },
  active: { editable: true, type: 'switch' },
}

export const settings = {
  id: { label: "ID" },
  ...sharedSettings,

  created_at: { editable: false, type: 'datetime', rules: [{ type: 'object' as const }] },
  updated_at: { editable: false, type: 'datetime', rules: [{ type: 'object' as const }] },  
};

export const initialSettings = {
  id: { hidden: true },
  ...sharedSettings,

  created_at: { hidden: true },
  updated_at: { hidden: true },
}

export const initialValues = {
  decision: null,
  title: '',
  description: '',
  weight: 0,
  priority: 0,
  rubric: '',
  active: true
};
