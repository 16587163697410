import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Space } from 'antd';
import moment from 'moment';

import FilterableTable from 'components/Admin/ObjectTable';

import { fetchUserAreas } from 'Redux/Admin/GoalsManager/UserAreas/action';

import type { FilterConfig } from '@types';
import type { TableColumnsType } from 'antd';
import type { IUserArea } from 'Redux/Admin/GoalsManager/UserAreas/type';

import rootReducer from 'Redux/root-reducer';

import { ADMIN_PATH } from "utils/routes";

type RootState = ReturnType<typeof rootReducer>;

const columns: TableColumnsType<IUserArea> = [
  {
    title: 'ID',
    dataIndex: 'id',
    width: 280,
    render: (_, record) => (
      <Space size="middle">
        <Link to={`${ADMIN_PATH}/goals/journeys/user-areas/${record.id}`}>{record.id}</Link>
      </Space>
    ),
  },
  {
    title: 'Name',
    dataIndex: 'name',
    width: 280,
  },
  {
    title: 'Masterplan',
    dataIndex: 'parent_masterplan',
    width: 280,
    render: (_, record) => (
      <Space size="middle">
        <Link to={`${ADMIN_PATH}/goals/journeys/masterplans/${record.parent_masterplan}`}>{record.parent_masterplan}</Link>
      </Space>
    ),
  },
  {
    title: 'User',
    dataIndex: 'user',
    width: 280,
    render: (_, record) => (
      <Space size="middle">
        <Link to={`${ADMIN_PATH}/users/${record.user}`}>{record.user}</Link>
      </Space>
    ),
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (text) => moment(text).format("YYYY-MM-DD HH:mm:ss"),
    width: 180,
  },
  {
    title: 'Action',
    key: 'action',
    width: 120,
    fixed: 'right',
    render: (_, record) => (
      <Space size="middle">
        <Link to={`${ADMIN_PATH}/goals/journeys/user-goals?area=${record.id}`}>View User Goals</Link>
      </Space>
    ),
  },
];

const UserAreas = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  // Extract query params
  const params = new URLSearchParams(location.search);

  const userId = params.get('user') || '';
  const masterplanId = params.get('masterplan') || '';
  const searchValue = params.get('search') || '';
  const pageValue = parseInt(params.get('page') || '1');
  const pageSizeValue = parseInt(params.get('size') || '50');

  // Redux state
  const { entities, ids, count, loading } = useSelector((state: RootState) => state.fetchUserAreas);

  // Fetch master plans whenever relevant query params change
  useEffect(() => {
    dispatch(
      fetchUserAreas({
        user: userId || undefined,
        parent_masterplan: masterplanId || undefined,
        search: searchValue || undefined,
        offset: (pageValue - 1) * pageSizeValue,
        limit: pageSizeValue,
      }, 
      () => {})
    );
  }, [userId, masterplanId, searchValue, pageValue, pageSizeValue, dispatch]);

  // Build filter configs for this page
  const filterConfigs: FilterConfig[] = [
    {
      type: 'search',
      paramName: 'search',
      label: 'Search',
      placeholder: 'Search user name and area name...'
    },
    {
      type: 'masterplan-select',
      paramName: 'masterplan',
      placeholder: 'Select masterplan...',
      label: 'Masterplan'
    },
    {
      type: 'user-select',
      paramName: 'user',
      placeholder: 'Select user...',
      label: 'User'
    },
  ];

  const dataItems = ids.map(id => entities[id]);

  return (
    <FilterableTable<IUserArea>
      columns={columns}
      data={dataItems}
      loading={loading}
      totalItems={count}
      filters={filterConfigs}
      createLink={`${ADMIN_PATH}/goals/journeys/user-areas/new`}
    />
  );
};

export default UserAreas;
