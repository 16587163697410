import { normalize, schema } from 'normalizr';
import { IState, ActionTypes } from './type';

const projectUserSchema = new schema.Entity('projectusers');
const projectUserListSchema = [projectUserSchema];

const initial: IState = {
  entities: {},
  ids: [],
  count: 0,
  loading: false,
};

const reducer = (state: IState = initial, action: any) => {
  switch (action.type) {
    case ActionTypes.REQUEST:
      return {
        ...state,
        loading: true
      };

    case ActionTypes.SUCCESS: {
      const normalizedData = normalize(action.payload.results, projectUserListSchema);

      return {
        ...state,
        entities: {
          ...state.entities,
          ...normalizedData.entities.projectusers,
        },
        ids: normalizedData.result as string[],
        count: action.payload.count as number,
        loading: false
      };
    }

    case ActionTypes.FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}

export default reducer;
