export const ActionTypes = {
  REQUEST: "ADMIN_FETCH_FOCUSED_PRIORITY_REQUEST",
  SUCCESS: "ADMIN_FETCH_FOCUSED_PRIORITY_SUCCESS",
  FAILURE: "ADMIN_FETCH_FOCUSED_PRIORITY_FAILURE",

  SUCCESS_SINGLE: "ADMIN_FETCH_TASK_SUCCESS",
}

export interface IFocusedPriority {
  id: string;
  created_at: string;
  updated_at: string;
  priority: number;
  week_starting: string;
  latest_deleted: boolean;
  user: string;
  prioritized_activity: string;
  task: string | null;
}

interface NormalizedEntities {
  [key: string]: IFocusedPriority;
}

export interface IState {
  entities: NormalizedEntities;
  ids: string[];
  count: number;
  loading: boolean;
  error: any;
}
