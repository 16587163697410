import { useEffect } from 'react';
import { Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from "react-router-dom";
import moment from 'moment';

import FilterableTable from 'components/Admin/ObjectTable';

import { fetchMasterPlans as fetchMasterPlansAction } from 'Redux/Admin/GoalsManager/MasterPlans/action';

import rootReducer from 'Redux/root-reducer';

import type { TableColumnsType } from 'antd';
import type { FilterConfig } from '@types';
import type { IMasterPlan } from 'Redux/Admin/GoalsManager/MasterPlans/type';

import { ADMIN_PATH } from "utils/routes";

type RootState = ReturnType<typeof rootReducer>;

const columns: TableColumnsType<IMasterPlan> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 280,
    render: (_, record) => (
      <Space size="middle">
        <Link to={`${ADMIN_PATH}/goals/journeys/masterplans/${record.id}`}>{record.id}</Link>
      </Space>
    ),
  },
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
    width: 280,
    render: (_, record) => (
      <Space size="middle">
        <Link to={`${ADMIN_PATH}/users/${record.id}`}>{record.id}</Link>
      </Space>
    ),
  },
  {
    title: 'Journey',
    dataIndex: 'journey',
    key: 'journey',
    width: 280,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: 120,
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (text) => moment(text).format("YYYY-MM-DD HH:mm:ss"),
    width: 180,
  },
  {
    title: 'Action',
    key: 'action',
    width: 120,
    fixed: 'right',
    render: (_, record) => (
      <Space size="middle">
        <Link to={`${ADMIN_PATH}/goals/journeys/user-areas?masterplan=${record.id}`}>View User Areas</Link>
      </Space>
    ),
  },
];

const MasterPlans = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const user = params.get('user');
  const journey = params.get('journey');
  const searchValue = params.get('search');
  const pageValue = parseInt(params.get('page') || '1');

  const { entities, ids, count, loading } = useSelector((state: RootState) => state.fetchMasterPlans);

  useEffect(() => {
    dispatch(fetchMasterPlansAction({ user, journey, search: searchValue, offset: (pageValue - 1) * 50, limit: 50 }, () => {}));
  }, [user, journey, searchValue, pageValue, dispatch]);

  // Build filter configs for this page
  const filterConfigs: FilterConfig[] = [
    {
      type: 'user-select',
      paramName: 'user',
      placeholder: 'Select user...',
      label: 'User'
    },
    {
      type: 'domain-select',
      paramName: 'journey',
      placeholder: 'Select journey...',
      label: 'Journey'
    }
  ];

  const dataItems = ids.map(id => entities[id]);

  return (
    <FilterableTable<IMasterPlan>
      columns={columns}
      data={dataItems}
      loading={loading}
      totalItems={count}
      filters={filterConfigs}
      createLink={`${ADMIN_PATH}/goals/journeys/masterplans/new`}
    />
  );
}

export default MasterPlans;
