export const ActionTypes = {
  REQUEST: "ADMIN_FETCH_SUBDOMAINS_REQUEST",
  SUCCESS: "ADMIN_FETCH_SUBDOMAINS_SUCCESS",
  FAILURE: "ADMIN_FETCH_SUBDOMAINS_FAILURE",
}

export interface IMasterPlan {
  id: string;
  created_at: string;
  updated_at: string;
  step: number;
  completed_at: string | null;
  status: string;
  is_private: boolean;
  premise: string;
  user: string;
  journey: string;
}

interface NormalizedEntities {
  [key: string]: IMasterPlan;
}

export interface IState {
  entities: NormalizedEntities;
  ids: string[];
  count: number;
  loading: boolean;
}
