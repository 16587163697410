import { useEffect, createContext } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Modal,
  Space,
  Spin,
  Card,
  Row,
  Col,
  Flex,
  Dropdown,
  MenuProps,
} from "antd";

import { DownOutlined } from "@ant-design/icons";

import ObjectForm from "components/Admin/ObjectForm";

import { RootState } from "Redux/store";
import { fetchProjectGoal, updateProjectGoal, createProjectGoal, deleteProjectGoal } from "Redux/Admin/ProjectManager/ProjectGoals/action";

import { settings, initialSettings, initialValues } from './const';
import { ADMIN_PATH } from "utils/routes";

import type { IProjectGoal } from 'Redux/Admin/ProjectManager/ProjectGoals/type';

const ProjectGoal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();
  const isNew = id === 'new';

  const { entities, loading, error } = useSelector((state: RootState) => state.fetchProjectGoals);

  const [modal, contextHolder] = Modal.useModal();

  const ReachableContext = createContext<string | null>(null);

  useEffect(() => {
    if (id && !isNew) {
      dispatch(fetchProjectGoal(id));
    }
  }, [dispatch, isNew, id]);

  const onFinish = (values: any) => {
    if (isNew) {
      dispatch(createProjectGoal(values, handleCreate));
    }
    if (id && !isNew) {
      dispatch(updateProjectGoal(values, id));
    }
  };

  const handleCreate = (project: IProjectGoal) => {
    if (project && project.id) {
      navigate(`${ADMIN_PATH}/project-manager/project-goals/${project.id}`);
    }
  }

  if (!id) {
    navigate(`${ADMIN_PATH}/project-manager/project-goals`, { replace: true });
    return null;
  }

  const projectGoal = isNew ? initialValues : entities[id];

  if (!projectGoal) {
    return <Spin />;
  }

  const menuItems: MenuProps['items'] = [
    /* {
      label: 'View Areas',
      key: '1',
    }, */
    {
      label: 'Delete Project Goal',
      key: '2',
      danger: true,
    }
  ];

  const modalConfig = {
    title: 'Delete the project plan?',
    content: <span>Are you sure you want to delete the project plan <b>{projectGoal.id}</b>?</span>,
    confirmLoading: true
  };

  const handleMenuClick: MenuProps['onClick'] = async (e) => {
    /* if (e.key === '1') {
      navigate(`${ADMIN_PATH}/goals/journeys/user-areas/?masterplan=${id}`);
    }
    else  */if (e.key === '2') {
      const confirmed = await modal.confirm(modalConfig);

      if (confirmed) {
        dispatch(deleteProjectGoal(id, () => {
          navigate(`${ADMIN_PATH}/project-manager/project-goals`, { replace: true });
        }));
      }
    }
  };

  const menuProps = {
    items: menuItems,
    onClick: handleMenuClick,
  };

  return (
    <ReachableContext.Provider value="Light">
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <Row>
          <Col span={12}>
            <Flex
              gap="middle"
              align="center"
              justify="space-between"
              style={{ marginBottom: 16 }}
            >
              <Link to={`${ADMIN_PATH}/project-manager/project-goals`}>Back</Link>
            </Flex>

            <Card title={`${isNew ? 'Create' : '' } Project Goal`} extra={
              isNew ? null : <Dropdown menu={menuProps} placement="bottom">
                <Button>
                  <Space>
                    Manage Project Goal
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            }>
              <ObjectForm
                data={projectGoal}
                errorData={error}
                isNew={isNew}
                settings={isNew ? initialSettings : settings}
                loading={loading}
                onFinish={onFinish}
              />
            </Card>
          </Col>
        </Row>
      </div>

      {contextHolder}
    </ReachableContext.Provider>
  );
}

export default ProjectGoal;
