const sharedSettings = {
  plan_progress: { editable: true, type: 'number', rules: [{ required: true }] },
  category_progress: { editable: true, type: 'number', rules: [{ required: true }] },
  weight: { editable: true, type: 'number', rules: [{ required: true }] },

  start_line: { editable: true, type: 'datetime', rules: [{ type: 'object' as const }] },
  dead_line: { editable: true, type: 'datetime', rules: [{ type: 'object' as const }] },

  category: {},
  created_by: { editable: true, type: 'project-user-select' },

  name: { editable: true, type: 'input', rules: [{ required: true }] },
  owner: { editable: true, type: 'project-user-select' },
  invited_owner: { editable: true, type: 'input' },

  is_hierarchical: { editable: true, type: 'switch' },

  purpose: { editable: true, type: 'textarea' },
  objective: { editable: true, type: 'textarea' },
  idea: { editable: true, type: 'textarea' },
  description: { editable: true, type: 'textarea' },

  status: { editable: true, type: 'select', rules: [{ options: [
    { value: 'in_progress', label: 'In Progress' },
    { value: 'on_hold', label: 'On Hold' },
    { value: 'completed', label: 'Completed' },
    { value: 'scheduled', label: 'Scheduled' },
  ] }] },

  duration: { editable: true, type: 'time', rules: [{ type: 'object' as const }] },
  last_notified: { editable: true, type: 'datetime', rules: [{ type: 'object' as const }] },

  is_archived: { editable: true, type: 'switch' },
  priority: { editable: true, type: 'number' },

  view: { editable: true, type: 'select', rules: [{ options: [
    { value: 'long', label: 'Long' },
    { value: 'short', label: 'Short' },
  ] }] },

  original_project_plan: {},
  public_project_plan_id: {},
  goal_public_id: {},
}

export const settings = {
  id: { label: "ID" },
  ...sharedSettings,

  created_at: { editable: false, type: 'datetime', rules: [{ type: 'object' as const }] },
  updated_at: { editable: false, type: 'datetime', rules: [{ type: 'object' as const }] },  
};

export const initialSettings = {
  id: { hidden: true },
  ...sharedSettings,

  created_at: { hidden: true },
  updated_at: { hidden: true },
  public_project_plan_id: { hidden: true },
}

export const initialValues = {
  plan_progress: 0,
  category_progress: 0,
  weight: 0,

  start_line: null,
  dead_line: null,

  category: "",
  created_by: "",

  name: "",
  owner: "",
  invited_owner: "",

  is_hierarchical: false,

  purpose: "",
  objective: "",
  idea: "",
  description: "",

  status: "scheduled",

  duration: "00:00:00",
  last_notified: null,

  is_archived: false,
  priority: 0,

  view: "long"
};
