const sharedSettings = {
  option_criteria: { editable: true, type: 'option-criterion-select', rules: [{ required: true }] },
  context: { editable: true, type: 'textarea', rules: [{ required: true }] },
  priority: { editable: true, type: 'number' },
}

export const settings = {
  id: { label: "ID" },
  ...sharedSettings,

  created_at: { editable: false, type: 'datetime', rules: [{ type: 'object' as const }] },
  updated_at: { editable: false, type: 'datetime', rules: [{ type: 'object' as const }] },  
};

export const initialSettings = {
  id: { hidden: true },
  ...sharedSettings,

  created_at: { hidden: true },
  updated_at: { hidden: true },
}

export const initialValues = {
  option_criteria: null,
  context: '',
  priority: 0,
};
