export const ActionTypes = {
  REQUEST: "ADMIN_FETCH_USER_GOALS_REQUEST",
  SUCCESS: "ADMIN_FETCH_USER_GOALS_SUCCESS",
  FAILURE: "ADMIN_FETCH_USER_GOALS_FAILURE",

  REQUEST_SINGLE: "ADMIN_FETCH_USER_GOAL_REQUEST",
  SUCCESS_SINGLE: "ADMIN_FETCH_USER_GOAL_SUCCESS",
  FAILURE_SINGLE: "ADMIN_FETCH_USER_GOAL_FAILURE",

  CREATE_REQUEST: "ADMIN_CREATE_USER_GOAL_REQUEST",
  CREATE_SUCCESS: "ADMIN_CREATE_USER_GOAL_SUCCESS",
  CREATE_FAILURE: "ADMIN_CREATE_USER_GOAL_FAILURE",

  UPDATE_REQUEST: "ADMIN_UPDATE_USER_GOAL_REQUEST",
  UPDATE_SUCCESS: "ADMIN_UPDATE_USER_GOAL_SUCCESS",
  UPDATE_FAILURE: "ADMIN_UPDATE_USER_GOAL_FAILURE",

  DELETE_REQUEST: "ADMIN_DELETE_USER_GOAL_REQUEST",
  DELETE_SUCCESS: "ADMIN_DELETE_USER_GOAL_SUCCESS",
  DELETE_FAILURE: "ADMIN_DELETE_USER_GOAL_FAILURE",
}

export interface IUserGoal {
  id: string;
  created_at: string;
  updated_at: string;
  category_name: string;
  is_custom: boolean;
  priority: number;
  weight: string;
  measurement: string;
  measurement_metric_name: string;
  measurement_start_value: number | null;
  measurement_current_value: number | null;
  measurement_target_value: number | null;
  frequency: string;
  gap_value: number | null;
  gap_percentage: number | null;
  goal_name: string;
  pleasure: string;
  pain: string;
  startline: string | null;
  deadline: string | null;
  habit: boolean;
  accountability: string;
  is_private: boolean;
  completed: boolean;
  user: string;
  parent_area: string;
}

interface NormalizedEntities {
  [key: string]: IUserGoal;
}

export interface IState {
  entities: NormalizedEntities;
  ids: string[];
  count: number;
  loading: boolean;
  error: any;
}
