import { useEffect, createContext } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Modal,
  Space,
  Spin,
  Card,
  Row,
  Col,
  Flex,
  Dropdown,
  MenuProps,
} from "antd";

import { DownOutlined } from "@ant-design/icons";

import ObjectForm from "components/Admin/ObjectForm";

import { RootState } from "Redux/store";
import { fetchUserGoal, createUserGoal, updateUserGoal, deleteUserGoal } from 'Redux/Admin/GoalsManager/UserGoals/action';

import { settings, initialSettings, initialValues } from './const';
import { ADMIN_PATH } from "utils/routes";

import type { IUserGoal } from 'Redux/Admin/GoalsManager/UserGoals/type';

const UserGoal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();
  const isNew = id === 'new';

  const { entities, loading, error } = useSelector((state: RootState) => state.fetchUserGoals);
  const [modal, contextHolder] = Modal.useModal();

  const ReachableContext = createContext<string | null>(null);

  useEffect(() => {
    if (id && !isNew) {
      dispatch(fetchUserGoal(id));
    }
  }, [dispatch, isNew, id]);

  const onFinish = (values: any) => {
    if (isNew) {
      dispatch(createUserGoal(values, handleCreate));
    }
    else if (id) {
      dispatch(updateUserGoal(values, id));
    }
  };

    const handleCreate = (userGoal: IUserGoal) => {
      if (userGoal && userGoal.id) {
        navigate(`${ADMIN_PATH}/goals/journeys/user-goals${userGoal.id}`);
      }
    }

  if (!id) {
    navigate(`${ADMIN_PATH}/goals/journeys/user-goals`, { replace: true });
    return null;
  }

  const userGoal = isNew ? initialValues : entities[id];

  if (!userGoal) {
    return <Spin />;
  }

  const menuItems: MenuProps['items'] = [
    {
      label: 'View Actions',
      key: '1',
    },
    {
      label: 'Delete Goal',
      key: '2',
      danger: true,
    }
  ];

  const modalConfig = {
    title: 'Delete the Goal?',
    content: <span>Are you sure you want to delete the goal <b>{userGoal.id}</b>?</span>,
    confirmLoading: true
  };

  const handleMenuClick: MenuProps['onClick'] = async (e) => {
    if (e.key === '1') {
      navigate(`${ADMIN_PATH}/goals/journeys/user-actions/?goal=${id}`);
    }
    else if (e.key === '2') {
      const confirmed = await modal.confirm(modalConfig);

      if (confirmed) {
        dispatch(deleteUserGoal(id, () => {
          navigate(`${ADMIN_PATH}/goals/journeys/user-goals`, { replace: true });
        }));
      }
    }
  };

  const menuProps = {
    items: menuItems,
    onClick: handleMenuClick,
  };

  return (
    <ReachableContext.Provider value="Light">
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <Row>
          <Col span={12}>
            <Flex
              gap="middle"
              align="center"
              justify="space-between"
              style={{ marginBottom: 16 }}
            >
              <Link to={`${ADMIN_PATH}/goals/journeys/user-goals`}>Back</Link>
            </Flex>

            <Card title="User Goal" extra={
              isNew ? null : <Dropdown menu={menuProps} placement="bottom">
                <Button>
                  <Space>
                    Manage Goal
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            }>
              <ObjectForm
                data={userGoal}
                errorData={error}
                isNew={isNew}
                settings={isNew ? initialSettings : settings}
                loading={loading}
                onFinish={onFinish}
              />
            </Card>
          </Col>
        </Row>
      </div>

      {contextHolder}
    </ReachableContext.Provider>
  );
}

export default UserGoal;
