import { useEffect } from 'react';
import { Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from "react-router-dom";
// import moment from 'moment';

import FilterableTable from 'components/Admin/ObjectTable';

import { fetchOptions } from 'Redux/Admin/DecisionManager/Options/action';

import rootReducer from 'Redux/root-reducer';

import type { TableColumnsType } from 'antd';
import type { FilterConfig } from '@types';
import type { IOption } from 'Redux/Admin/DecisionManager/Options/type';

import { ADMIN_PATH } from "utils/routes";

type RootState = ReturnType<typeof rootReducer>;

const columns: TableColumnsType<IOption> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 120,
    render: (_, record) => (
      <Space size="middle">
        <Link to={`${ADMIN_PATH}/decision-manager/options/${record.id}`}>{record.id}</Link>
      </Space>
    ),
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: 280,
  },
  {
    title: 'Decision',
    dataIndex: 'decision',
    key: 'decision',
    width: 120,
    render: (_, record) => record.decision
  },
];

const Options = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const user = params.get('user');
  const decision = params.get('decision');
  const searchValue = params.get('search');
  const pageValue = parseInt(params.get('page') || '1');

  const { entities, ids, count, loading } = useSelector((state: RootState) => state.fetchOptions);

  useEffect(() => {
    dispatch(fetchOptions({ user, decision, search: searchValue, offset: (pageValue - 1) * 50, limit: 50 }, () => {}));
  }, [user, decision, searchValue, pageValue, dispatch]);

  // Build filter configs for this page
  const filterConfigs: FilterConfig[] = [
    {
      type: 'search',
      paramName: 'search',
      label: 'Search',
      placeholder: 'Search...'
    },
    {
      type: 'decision-select',
      paramName: 'decision',
      placeholder: 'Select decision...',
      label: 'Decision'
    },
  ];

  const dataItems = ids.map(id => entities[id]);

  return (
    <FilterableTable<IOption>
      columns={columns}
      data={dataItems}
      loading={loading}
      totalItems={count}
      createLink={`${ADMIN_PATH}/decision-manager/options/new`}
      filters={filterConfigs}
    />
  );
}

export default Options;
