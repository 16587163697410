export const ActionTypes = {
  REQUEST: "ADMIN_FETCH_TASKS_REQUEST",
  SUCCESS: "ADMIN_FETCH_TASKS_SUCCESS",
  FAILURE: "ADMIN_FETCH_TASKS_FAILURE",

  SUCCESS_SINGLE: "ADMIN_FETCH_TASK_SUCCESS",
  UPDATE_SUCCESS: "ADMIN_UPDATE_TASK_SUCCESS",
  CREATE_SUCCESS: "ADMIN_CREATE_TASK_SUCCESS",
  DELETE_SUCCESS: "ADMIN_DELETE_TASK_SUCCESS",
}

export interface ITask {
  id: string;
  created_at: null | string;
  updated_at: null | string;
  name: string;
  deadline: string;
  estimated_duration: number;
  priority: string;
  completed: boolean;
  user: string;
}

interface NormalizedEntities {
  [key: string]: ITask;
}

export interface IState {
  entities: NormalizedEntities;
  ids: string[];
  count: number;
  loading: boolean;
  error: any;
}
