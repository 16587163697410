export const ActionTypes = {
  REQUEST: "ADMIN_FETCH_USER_AREAS_REQUEST",
  SUCCESS: "ADMIN_FETCH_USER_AREAS_SUCCESS",
  FAILURE: "ADMIN_FETCH_USER_AREAS_FAILURE",

  REQUEST_SINGLE: "ADMIN_FETCH_USER_AREA_REQUEST",
  SUCCESS_SINGLE: "ADMIN_FETCH_USER_AREA_SUCCESS",
  FAILURE_SINGLE: "ADMIN_FETCH_USER_AREA_FAILURE",

  CREATE_REQUEST: "ADMIN_CREATE_USER_AREA_REQUEST",
  CREATE_SUCCESS: "ADMIN_CREATE_USER_AREA_SUCCESS",
  CREATE_FAILURE: "ADMIN_CREATE_USER_AREA_FAILURE",

  UPDATE_REQUEST: "ADMIN_UPDATE_USER_AREA_REQUEST",
  UPDATE_SUCCESS: "ADMIN_UPDATE_USER_AREA_SUCCESS",
  UPDATE_FAILURE: "ADMIN_UPDATE_USER_AREA_FAILURE",

  DELETE_REQUEST: "ADMIN_DELETE_USER_AREA_REQUEST",
  DELETE_SUCCESS: "ADMIN_DELETE_USER_AREA_SUCCESS",
  DELETE_FAILURE: "ADMIN_DELETE_USER_AREA_FAILURE",
}

export interface IUserArea {
  id: string;
  created_at: string;
  updated_at: string;
  name: string;
  weight: string;
  is_custom: boolean;
  is_locked: boolean;
  is_private: boolean;
  color: string;
  user: string;
  parent_masterplan: string;
}

interface NormalizedEntities {
  [key: string]: IUserArea;
}

export interface IState {
  entities: NormalizedEntities;
  ids: string[];
  count: number;
  loading: boolean;
  error: any;
}
