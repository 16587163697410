import { useEffect, createContext } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Spin,
  Card,
  Row,
  Col,
  Flex,
} from "antd";

import ObjectForm from "components/Admin/ObjectForm";

import { RootState } from "Redux/store";

import { settings } from './const';
import { ADMIN_PATH } from "utils/routes";
import { fetchPrioritizedActivities } from 'Redux/Admin/TaskManager/PrioritizedActivities/action';

const PrioritizedActivity = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();
  const isNew = id === 'new';

  const { entities, loading, error } = useSelector((state: RootState) => state.fetchPrioritizedActivities);

  const ReachableContext = createContext<string | null>(null);

  useEffect(() => {
    if (id && !isNew) {
      dispatch(fetchPrioritizedActivities(id));
    }
  }, [dispatch, isNew, id]);

  if (!id) {
    navigate(`${ADMIN_PATH}/task-manager/prioritized-activities`, { replace: true });
    return null;
  }

  const prioritizedActivity = entities[id];

  if (!prioritizedActivity) {
    return <Spin />;
  }

  return (
    <ReachableContext.Provider value="Light">
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <Row>
          <Col span={12}>
            <Flex
              gap="middle"
              align="center"
              justify="space-between"
              style={{ marginBottom: 16 }}
            >
              <Link to={`${ADMIN_PATH}/task-manager/prioritized-activities`}>Back</Link>
            </Flex>

            <Card title="Prioritized Activity">
              <ObjectForm
                data={prioritizedActivity}
                errorData={error}
                settings={settings}
                loading={loading}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </ReachableContext.Provider>
  );
}

export default PrioritizedActivity;
