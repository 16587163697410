import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";

import {
  ADMIN_PATH,
} from "utils/routes";

function GTMLoader() {
  const location = useLocation();
  const [gtmLoaded, setGtmLoaded] = useState(false);

  useEffect(() => {
    const isAdminRoute = location.pathname.startsWith(ADMIN_PATH);

    if (!isAdminRoute && !gtmLoaded) {
      // Initialize GTM if not already loaded and we are not on admin route
      TagManager.initialize({ gtmId: "GTM-5KCPG3C" });
      setGtmLoaded(true);
    }
  }, [location, gtmLoaded]);

  return null; // This component just manages GTM injection
}

export default GTMLoader;
