export const ActionTypes = {
  REQUEST: "ADMIN_FETCH_PROJECT_PLANS_REQUEST",
  SUCCESS: "ADMIN_FETCH_PROJECT_PLANS_SUCCESS",
  FAILURE: "ADMIN_FETCH_PROJECT_PLANS_FAILURE",

  REQUEST_SINGLE: "ADMIN_FETCH_PROJECT_PLAN_REQUEST",
  SUCCESS_SINGLE: "ADMIN_FETCH_PROJECT_PLAN_SUCCESS",
  FAILURE_SINGLE: "ADMIN_FETCH_PROJECT_PLAN_FAILURE",

  UPDATE_REQUEST: "ADMIN_UPDATE_PROJECT_PLAN_REQUEST",
  UPDATE_SUCCESS: "ADMIN_UPDATE_PROJECT_PLAN_SUCCESS",
  UPDATE_FAILURE: "ADMIN_UPDATE_PROJECT_PLAN_FAILURE",

  CREATE_REQUEST: "ADMIN_CREATE_PROJECT_PLAN_REQUEST",
  CREATE_SUCCESS: "ADMIN_CREATE_PROJECT_PLAN_SUCCESS",
  CREATE_FAILURE: "ADMIN_CREATE_PROJECT_PLAN_FAILURE",

  DELETE_REQUEST: "ADMIN_DELETE_PROJECT_PLAN_REQUEST",
  DELETE_SUCCESS: "ADMIN_DELETE_PROJECT_PLAN_SUCCESS",
  DELETE_FAILURE: "ADMIN_DELETE_PROJECT_PLAN_FAILURE",
}

export interface ICriteriaTag {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  weight: number;
  color: string;
  project_plan: number;
  action_item: number | null;
}

export interface IProjectPlan {
  id: number;
  encrypted_id: string;
  criteria_tags: ICriteriaTag[];
  created_at: string;
  updated_at: string;
  plan_progress: string;
  category_progress: string;
  weight: string;
  start_line: string;
  dead_line: string;
  start_line_change_timestamp: string | null;
  end_line_change_timestamp: string | null;
  name: string;
  invited_owner: string;
  is_hierarchical: boolean;
  purpose: string;
  objective: string;
  idea: string;
  description: string | null;
  status: string;
  duration: string;
  last_notified: string | null;
  is_archived: boolean;
  priority: string | null;
  view: string;
  public_project_plan_id: string;
  goal_public_id: string;
  lock_start_end_dates: boolean;
  category: string | null;
  created_by: number;
  owner: number;
  original_project_plan: string | null;
}

interface NormalizedEntities {
  [key: string]: IProjectPlan;
}

export interface IState {
  entities: NormalizedEntities;
  ids: string[];
  count: number;
  loading: boolean;
  error: any;
}
