export const ActionTypes = {
  REQUEST: "ADMIN_FETCH_OPTION_CRITERIA_REQUEST",
  SUCCESS: "ADMIN_FETCH_OPTION_CRITERIA_SUCCESS",
  FAILURE: "ADMIN_FETCH_OPTION_CRITERIA_FAILURE",

  SUCCESS_SINGLE: "ADMIN_FETCH_OPTION_CRITERION_SUCCESS",
  UPDATE_SUCCESS: "ADMIN_UPDATE_OPTION_CRITERION_SUCCESS",
  CREATE_SUCCESS: "ADMIN_CREATE_OPTION_CRITERION_SUCCESS",
  DELETE_SUCCESS: "ADMIN_DELETE_OPTION_CRITERION_SUCCESS",
}

export interface IOptionCriterion {
  id: number;
  created_at: string;
  updated_at: string;
  score: string;
  second_stage_score: string;
  facts: string;
  is_blank: boolean;
  is_na: boolean;
  second_stage_is_blank: boolean;
  second_stage_is_na: boolean;
  option: number;
  criteria: number;
}

interface NormalizedEntities {
  [key: string]: IOptionCriterion;
}

export interface IState {
  entities: NormalizedEntities;
  ids: string[];
  count: number;
  loading: boolean;
  error: any;
}
