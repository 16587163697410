export const ActionTypes = {
  REQUEST: "ADMIN_FETCH_CONS_REQUEST",
  SUCCESS: "ADMIN_FETCH_CONS_SUCCESS",
  FAILURE: "ADMIN_FETCH_CONS_FAILURE",

  SUCCESS_SINGLE: "ADMIN_FETCH_CON_SUCCESS",
  UPDATE_SUCCESS: "ADMIN_UPDATE_CON_SUCCESS",
  CREATE_SUCCESS: "ADMIN_CREATE_CON_SUCCESS",
  DELETE_SUCCESS: "ADMIN_DELETE_CON_SUCCESS",
}

export interface ICon {
  id: number;
  created_at: string;
  updated_at: string;
  context: string;
  mitigation: string;
  priority: number | null;
  option_criteria: number;
}

interface NormalizedEntities {
  [key: string]: ICon;
}

export interface IState {
  entities: NormalizedEntities;
  ids: string[];
  count: number;
  loading: boolean;
  error: any;
}
