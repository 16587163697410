const sharedSettings = {
  project_plan: { editable: true, type: 'project-plan-select', rules: [{ required: true }] },

  name: { editable: true, type: 'input', rules: [{ required: true }] },
  duration: { editable: false, type: 'time', rules: [{ type: 'object' as const }] },

  plan_progress: { editable: true, type: 'number', rules: [{ required: true }] },
  category_progress: { editable: true, type: 'number', rules: [{ required: true }] },
  weight: { editable: true, type: 'number', rules: [{ required: true }] },

  start: { editable: true, type: 'number' },
  current: { editable: true, type: 'number' },
  target: { editable: true, type: 'number' },

  start_line: { editable: false, type: 'datetime', rules: [{ type: 'object' as const }] },
  dead_line: { editable: false, type: 'datetime', rules: [{ type: 'object' as const }] },

  order_id: { editable: true, type: 'number' },

  start_date_grace_time_left: { hidden: true },
  is_start_date_locked: { hidden: true },
  end_date_grace_time_left: { hidden: true },
  is_end_date_locked: { hidden: true },
  start_change_timestamp: { hidden: true },
  start_line_change_timestamp: { hidden: true },
  end_line_change_timestamp: { hidden: true }
}

export const settings = {
  id: { label: "ID" },
  ...sharedSettings,

  created_at: { editable: false, type: 'datetime', rules: [{ type: 'object' as const }] },
  updated_at: { editable: false, type: 'datetime', rules: [{ type: 'object' as const }] },  
};

export const initialSettings = {
  id: { hidden: true },
  ...sharedSettings,

  created_at: { hidden: true },
  updated_at: { hidden: true },
}

export const initialValues = {
  project_plan: null,
  name: "",
  duration: "00:00:00",
  plan_progress: 0,
  category_progress: 0,
  weight: 0,
  start: 0,
  current: 0,
  target: 0,
  start_line: null,
  dead_line: null,
  order_id: 0,
};
