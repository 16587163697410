import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";

import styled from 'styled-components';

// Auth
import Landing from "screens/AuthScenes/Landing";
import Verification from "screens/AuthScenes/Verification";

import SignIn from 'screens/AuthScenes/SignIn';
import SignUp from 'screens/AuthScenes/SignUp';

import ForgotPassword from "screens/AuthScenes/ForgotPassword";
import ResetPassword from "screens/AuthScenes/ResetPassword";
import ForgotPasswordSuccess from "screens/AuthScenes/ForgotPasswordSuccess";

import AdminScenes from 'screens/AdminScenes';
import AdminUsers from 'screens/AdminScenes/Users';
import AdminUser from 'screens/AdminScenes/User';

import Masterplans from 'screens/AdminScenes/GoalsManager/Journeys/Masterplans';
import Masterplan from 'screens/AdminScenes/GoalsManager/Journeys/Masterplans/Masterplan';

import UserAreas from 'screens/AdminScenes/GoalsManager/Journeys/UserAreas';
import UserArea from 'screens/AdminScenes/GoalsManager/Journeys/UserAreas/UserArea';

import UserGoals from 'screens/AdminScenes/GoalsManager/Journeys/UserGoals';
import UserGoal from 'screens/AdminScenes/GoalsManager/Journeys/UserGoals/UserGoal';

import UserActions from 'screens/AdminScenes/GoalsManager/Journeys/UserActions';
import UserAction from 'screens/AdminScenes/GoalsManager/Journeys/UserActions/UserAction';

import Domains from 'screens/AdminScenes/GoalsManager/Domains';
import Domain from 'screens/AdminScenes/GoalsManager/Domains/Domain';

// Project Manager
import ProjectPlans from 'screens/AdminScenes/ProjectManager/ProjectPlans';
import ProjectPlan from 'screens/AdminScenes/ProjectManager/ProjectPlans/ProjectPlan';

import Goals from 'screens/AdminScenes/ProjectManager/Goals';
import Goal from 'screens/AdminScenes/ProjectManager/Goals/Goal';

import ActionCategories from 'screens/AdminScenes/ProjectManager/ActionCategories';
import ActionCategory from 'screens/AdminScenes/ProjectManager/ActionCategories/ActionCategory';

import ActionItems from 'screens/AdminScenes/ProjectManager/ActionItems';
import ActionItem from 'screens/AdminScenes/ProjectManager/ActionItems/ActionItem';

// Task Manager
import Tasks from 'screens/AdminScenes/TaskManager/Tasks';
import Task from 'screens/AdminScenes/TaskManager/Tasks/Task';

import PrioritizedActivities from 'screens/AdminScenes/TaskManager/PrioritizedActivities';
import PrioritizedActivity from 'screens/AdminScenes/TaskManager/PrioritizedActivities/PrioritizedActivity';

import FocusedPriorities from 'screens/AdminScenes/TaskManager/FocusedPriorities';
import FocusedPriority from 'screens/AdminScenes/TaskManager/FocusedPriorities/FocusedPriority';

// Decision Manager
import Decisions from 'screens/AdminScenes/DecisionManager/Decisions';
import Decision from 'screens/AdminScenes/DecisionManager/Decisions/Decision';

import Options from 'screens/AdminScenes/DecisionManager/Options';
import Option from 'screens/AdminScenes/DecisionManager/Options/Option';

import Criteria from 'screens/AdminScenes/DecisionManager/Criteria';
import Criterion from 'screens/AdminScenes/DecisionManager/Criteria/Criterion';

import OptionCriteria from 'screens/AdminScenes/DecisionManager/OptionCriteria';
import OptionCriterion from 'screens/AdminScenes/DecisionManager/OptionCriteria/OptionCriterion';

import Pros from 'screens/AdminScenes/DecisionManager/Pros';
import Pro from 'screens/AdminScenes/DecisionManager/Pros/Pro';

import Cons from 'screens/AdminScenes/DecisionManager/Cons';
import Con from 'screens/AdminScenes/DecisionManager/Cons/Con';

import { Protected, Guest } from "Hooks/ProtectedRoutes";
import { CheckAuth } from "Redux/Token/action";

import {
  SIGN_IN_PATH,
  SIGN_UP_PATH,
  FORGOT_PASSWORD_PATH,
  FORGOT_PASSWORD_SUCCESS_PATH,
  RESET_PASSWORD_PATH,
  ADMIN_PATH,
  INDEX_PATH,
  OTP_PATH
} from "utils/routes";

const AppRoutes = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    function handleWindowFocus() {
      dispatch(CheckAuth({ silent: true }));
    }

    if (process.env.NODE_ENV !== 'development')
      window.addEventListener("focus", handleWindowFocus);

    return () => {
      window.removeEventListener("focus", handleWindowFocus);
    };
  }, []);

  return (
    <Container>
      <Routes>
        {/* <Route index element={<Guest><Navigate to={SIGN_IN_PATH} replace={true} /></Guest>} /> */}

        <Route path={INDEX_PATH} exact element={
          <Protected>
            <Landing />
          </Protected>
        } />

        <Route path={OTP_PATH} exact element={
          <Guest>
            <Verification />
          </Guest>
        } />

        <Route path={SIGN_IN_PATH} exact element={
          <Guest>
            <SignIn />
          </Guest>
        } />

        <Route path={SIGN_UP_PATH} exact element={
          <Guest>
            <SignUp />
          </Guest>
        } />

        <Route path={FORGOT_PASSWORD_PATH} exact element={
          <Guest>
            <ForgotPassword />
          </Guest>
        } />

        <Route path={FORGOT_PASSWORD_SUCCESS_PATH} exact element={
          <Guest>
            <ForgotPasswordSuccess />
          </Guest>
        } />

        <Route path={RESET_PASSWORD_PATH} exact element={
          <Guest>
            <ResetPassword />
          </Guest>
        } />

        <Route path={`${ADMIN_PATH}/*`} element={
          <Protected>
            <AdminScenes />
          </Protected>
        }>
          <Route path={'users'} element={<AdminUsers />} />
          <Route path={'users/:id/*'} element={<AdminUser />} />

          {/* Goal Manager */}
          <Route path={'goals/journeys/masterplans'} element={<Masterplans />} />
          <Route path={'goals/journeys/masterplans/:id'} element={<Masterplan />} />

          <Route path={'goals/journeys/user-areas'} element={<UserAreas />} />
          <Route path={'goals/journeys/user-areas/:id'} element={<UserArea />} />

          <Route path={'goals/journeys/user-goals'} element={<UserGoals />} />
          <Route path={'goals/journeys/user-goals/:id'} element={<UserGoal />} />

          <Route path={'goals/journeys/user-actions'} element={<UserActions />} />
          <Route path={'goals/journeys/user-actions/:id'} element={<UserAction />} />

          {/* Domains */}
          <Route path={'goals/system/domains'} element={<Domains />} />
          <Route path={'goals/system/domains/:id'} element={<Domain />} />

          {/* Project Manager */}
          <Route path={'project-manager/project-plans'} element={<ProjectPlans />} />
          <Route path={'project-manager/project-plans/:id'} element={<ProjectPlan />} />

          <Route path={'project-manager/project-goals'} element={<Goals />} />
          <Route path={'project-manager/project-goals/:id'} element={<Goal />} />

          <Route path={'project-manager/action-categories'} element={<ActionCategories />} />
          <Route path={'project-manager/action-categories/:id'} element={<ActionCategory />} />

          <Route path={'project-manager/action-items'} element={<ActionItems />} />
          <Route path={'project-manager/action-items/:id'} element={<ActionItem />} />

          {/* Task Manager */}
          <Route path={'task-manager/tasks'} element={<Tasks />} />
          <Route path={'task-manager/tasks/:id'} element={<Task />} />

          <Route path={'task-manager/prioritized-activities'} element={<PrioritizedActivities />} />
          <Route path={'task-manager/prioritized-activities/:id'} element={<PrioritizedActivity />} />

          <Route path={'task-manager/focused-priorities'} element={<FocusedPriorities />} />
          <Route path={'task-manager/focused-priorities/:id'} element={<FocusedPriority />} />

          {/* Decision Manager */}
          <Route path={'decision-manager/decisions'} element={<Decisions />} />
          <Route path={'decision-manager/decisions/:id'} element={<Decision />} />

          <Route path={'decision-manager/options'} element={<Options />} />
          <Route path={'decision-manager/options/:id'} element={<Option />} />

          <Route path={'decision-manager/criteria'} element={<Criteria />} />
          <Route path={'decision-manager/criteria/:id'} element={<Criterion />} />

          <Route path={'decision-manager/option-criteria'} element={<OptionCriteria />} />
          <Route path={'decision-manager/option-criteria/:id'} element={<OptionCriterion />} />

          <Route path={'decision-manager/pros'} element={<Pros />} />
          <Route path={'decision-manager/pros/:id'} element={<Pro />} />

          <Route path={'decision-manager/cons'} element={<Cons />} />
          <Route path={'decision-manager/cons/:id'} element={<Con />} />
        </Route>
      </Routes>
    </Container>
  );
};

const Container = styled.div``;

export default AppRoutes;
