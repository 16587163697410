import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash/debounce";
import { Select, Spin } from "antd";
import type { SelectProps } from "antd";

import { RootState } from "Redux/store";
import { fetchDomains } from "Redux/Admin/GoalsManager/Domains/action";

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, "options" | "children"> {
  /** optional debouncing time for search input */
  debounceTimeout?: number;
}

/**
 * A debounced user-select component that fetches user data 
 * from the server whenever the user types in the search box.
 */
function DomainSelect<
  ValueType extends {
    key?: string;
    label: React.ReactNode;
    value: string | number;
  } = any
>({
  debounceTimeout = 800,
  ...props
}: DebounceSelectProps<ValueType>) {
  const dispatch = useDispatch();
  const { entities, ids, loading } = useSelector((state: RootState) => state.fetchDomains);

  // Dispatch an action on first render to load initial data
  useEffect(() => {
    dispatch(fetchDomains({ search: "", offset: 0, limit: 50 }, () => {}));
  }, [dispatch]);

  // Create a debounced version of the search handler
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      dispatch(fetchDomains({ search: value, offset: 0, limit: 50 }, () => {}));
    };
    return debounce(loadOptions, debounceTimeout);
  }, [debounceTimeout, dispatch]);

  // Transform your store's user data into AntD <Select> options
  const data = ids.map((id) => {
    const item = entities[id];

    return {
      label: item.name,
      value: item.id,
    };
  });

  return (
    <Select
      // Spread any "leftover" props you might want to pass 
      // (e.g., value, onChange, mode, etc.)
      {...props}
      value={props.value || undefined}
      showSearch
      loading={loading}
      options={data}
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={loading ? <Spin size="small" /> : null}
      labelInValue
    />
  );
}

export default DomainSelect;
