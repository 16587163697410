import Notify from "utils/notify";

import {
  adminFetchPros,
  adminFetchPro,
  adminCreatePro,
  adminUpdatePro,
  adminDeletePro
} from "api";

import { ActionTypes, IPro } from "./type";

export const fetchPros = (payload: any, callback?: () => any) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.REQUEST });

    const { data } = await adminFetchPros(payload);

    if (Array.isArray(data.results)) {
      dispatch({
        type: ActionTypes.SUCCESS,
        payload: data
      });
    }
    else {
      dispatch({
        type: ActionTypes.FAILURE,
        payload: data
      });

      Notify.error(data, true);
    }

    if (callback)
      callback();
  } catch (error) {
    console.error(error);

    dispatch({
      type: ActionTypes.FAILURE,
      payload: error
    });

    Notify.error(error, true);
  }
};

export const fetchPro = (payload: any, callback?: () => any) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.REQUEST });

    const { data } = await adminFetchPro(payload);

    dispatch({
      type: ActionTypes.SUCCESS_SINGLE,
      payload: data
    });

    if (callback)
      callback();
  } catch (error) {
    console.error(error);

    dispatch({
      type: ActionTypes.FAILURE,
      payload: error
    });

    Notify.error(error, true);
  }
};

export const createPro = (payload: any, callback?: (data: IPro) => any) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.REQUEST });

    const { data } = await adminCreatePro(payload);

    dispatch({
      type: ActionTypes.CREATE_SUCCESS,
      payload: data
    });

    Notify.success('Pro created successfully!');

    if (callback)
      callback(data);
  } catch (error: any) {
    console.error(error);

    dispatch({
      type: ActionTypes.FAILURE,
      payload: error?.response?.data
    });

    Notify.error(error, true);
  }
};

export const updatePro = (payload: any, id: string, callback?: () => any) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.REQUEST });

    const { data } = await adminUpdatePro(payload, id);

    dispatch({
      type: ActionTypes.UPDATE_SUCCESS,
      payload: data
    });

    Notify.success('Pro updated successfully!');

    if (callback)
      callback();
  } catch (error: any) {
    console.error(error);

    dispatch({
      type: ActionTypes.FAILURE,
      payload: error?.response?.data
    });

    Notify.error(error, true);
  }
};

export const deletePro = (id: string, callback?: () => any) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.REQUEST });

    await adminDeletePro(id);

    dispatch({
      type: ActionTypes.DELETE_SUCCESS,
      payload: id
    });

    Notify.success('Pro deleted successfully!');

    if (callback)
      callback();
  } catch (error) {
    console.error(error);

    dispatch({
      type: ActionTypes.FAILURE,
      payload: error
    });

    Notify.error(error, true);
  }
};
