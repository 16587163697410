import Notify from "utils/notify";

import {
  adminFetchCons,
  adminFetchCon,
  adminCreateCon,
  adminUpdateCon,
  adminDeleteCon
} from "api";

import { ActionTypes, ICon } from "./type";

export const fetchCons = (payload: any, callback?: () => any) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.REQUEST });

    const { data } = await adminFetchCons(payload);

    if (Array.isArray(data.results)) {
      dispatch({
        type: ActionTypes.SUCCESS,
        payload: data
      });
    }
    else {
      dispatch({
        type: ActionTypes.FAILURE,
        payload: data
      });

      Notify.error(data, true);
    }

    if (callback)
      callback();
  } catch (error) {
    console.error(error);

    dispatch({
      type: ActionTypes.FAILURE,
      payload: error
    });

    Notify.error(error, true);
  }
};

export const fetchCon = (payload: any, callback?: () => any) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.REQUEST });

    const { data } = await adminFetchCon(payload);

    dispatch({
      type: ActionTypes.SUCCESS_SINGLE,
      payload: data
    });

    if (callback)
      callback();
  } catch (error) {
    console.error(error);

    dispatch({
      type: ActionTypes.FAILURE,
      payload: error
    });

    Notify.error(error, true);
  }
};

export const createCon = (payload: any, callback?: (data: ICon) => any) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.REQUEST });

    const { data } = await adminCreateCon(payload);

    dispatch({
      type: ActionTypes.CREATE_SUCCESS,
      payload: data
    });

    Notify.success('Con created successfully!');

    if (callback)
      callback(data);
  } catch (error: any) {
    console.error(error);

    dispatch({
      type: ActionTypes.FAILURE,
      payload: error?.response?.data
    });

    Notify.error(error, true);
  }
};

export const updateCon = (payload: any, id: string, callback?: () => any) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.REQUEST });

    const { data } = await adminUpdateCon(payload, id);

    dispatch({
      type: ActionTypes.UPDATE_SUCCESS,
      payload: data
    });

    Notify.success('Con updated successfully!');

    if (callback)
      callback();
  } catch (error: any) {
    console.error(error);

    dispatch({
      type: ActionTypes.FAILURE,
      payload: error?.response?.data
    });

    Notify.error(error, true);
  }
};

export const deleteCon = (id: string, callback?: () => any) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.REQUEST });

    await adminDeleteCon(id);

    dispatch({
      type: ActionTypes.DELETE_SUCCESS,
      payload: id
    });

    Notify.success('Con deleted successfully!');

    if (callback)
      callback();
  } catch (error) {
    console.error(error);

    dispatch({
      type: ActionTypes.FAILURE,
      payload: error
    });

    Notify.error(error, true);
  }
};
