const sharedSettings = {
  step: { editable: true, type: 'number' },
  status: { editable: true, type: 'select', rules: [{ required: true, options: [
    { value: 'In progress', label: 'In progress' },
    { value: 'Completed', label: 'Completed' },
    { value: 'Not started', label: 'Not started' },
  ] }] },

  is_private: { editable: true, type: 'switch' },
}

export const settings = {
  id: { label: "ID" },

  ...sharedSettings,

  premise: { editable: true, type: 'input' },
  mission_statement: { label: "Public ID" },
  viewed_masterplan_screen: { editable: true, label: "Is completed", type: 'switch' },
  completed_at: { editable: false, type: 'datetime', rules: [{ type: 'object' as const }] },

  created_at: { editable: false, type: 'datetime', rules: [{ type: 'object' as const }] },
  updated_at: { editable: false, type: 'datetime', rules: [{ type: 'object' as const }] },

  user: { editable: true, type: 'user-select', rules: [{ required: true }] },
  journey: { editable: true, type: 'subdomain-select', rules: [{ required: true }] },
};

export const initialSettings = {
  id: { hidden: true },
  mission_statement: { hidden: true },
  viewed_masterplan_screen: { hidden: true },
  completed_at: { hidden: true },
  created_at: { hidden: true },
  updated_at: { hidden: true },

  user: { editable: true, type: 'user-select', rules: [{ required: true }] },
  journey: { editable: true, type: 'subdomain-select', rules: [{ required: true }] },

  ...sharedSettings,

  premise: { editable: true, type: 'input' },
}

export const initialValues = {
  step: 0,
  completed_at: null,
  status: "Not started",
  is_private: false,
  premise: "",
  user: "",
  journey: "",
}
