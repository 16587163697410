export const ActionTypes = {
  REQUEST: "ADMIN_FETCH_PROJECT_GOALS_REQUEST",
  SUCCESS: "ADMIN_FETCH_PROJECT_GOALS_SUCCESS",
  FAILURE: "ADMIN_FETCH_PROJECT_GOALS_FAILURE",

  REQUEST_SINGLE: "ADMIN_FETCH_PROJECT_GOAL_REQUEST",
  SUCCESS_SINGLE: "ADMIN_FETCH_PROJECT_GOAL_SUCCESS",
  FAILURE_SINGLE: "ADMIN_FETCH_PROJECT_GOAL_FAILURE",

  UPDATE_REQUEST: "ADMIN_UPDATE_PROJECT_GOAL_REQUEST",
  UPDATE_SUCCESS: "ADMIN_UPDATE_PROJECT_GOAL_SUCCESS",
  UPDATE_FAILURE: "ADMIN_UPDATE_PROJECT_GOAL_FAILURE",

  CREATE_REQUEST: "ADMIN_CREATE_PROJECT_GOAL_REQUEST",
  CREATE_SUCCESS: "ADMIN_CREATE_PROJECT_GOAL_SUCCESS",
  CREATE_FAILURE: "ADMIN_CREATE_PROJECT_GOAL_FAILURE",

  DELETE_REQUEST: "ADMIN_DELETE_PROJECT_GOAL_REQUEST",
  DELETE_SUCCESS: "ADMIN_DELETE_PROJECT_GOAL_SUCCESS",
  DELETE_FAILURE: "ADMIN_DELETE_PROJECT_GOAL_FAILURE",
}

export interface IProjectGoal {
  id: number;
  created_at: string;
  updated_at: string;
  start: string | null;
  current: string | null;
  target: string | null;
  start_change_timestamp: string | null;
  name: string | null;
  progress: string;
  label: string | null;
  distance_unit: string | null;
  energy_unit: string | null;
  height_unit: string | null;
  time_unit: string | null;
  volume_unit: string | null;
  weight_unit: string | null;
  count_unit: string | null;
  scale_unit: string | null;
  currency_unit: string | null;
  project_plan: number;
  custom_unit: string | null;
}

interface NormalizedEntities {
  [key: string]: IProjectGoal;
}

export interface IState {
  entities: NormalizedEntities;
  ids: string[];
  count: number;
  loading: boolean;
  error: any;
}
