export const ActionTypes = {
  REQUEST: "ADMIN_FETCH_PROS_REQUEST",
  SUCCESS: "ADMIN_FETCH_PROS_SUCCESS",
  FAILURE: "ADMIN_FETCH_PROS_FAILURE",

  SUCCESS_SINGLE: "ADMIN_FETCH_PRO_SUCCESS",
  UPDATE_SUCCESS: "ADMIN_UPDATE_PRO_SUCCESS",
  CREATE_SUCCESS: "ADMIN_CREATE_PRO_SUCCESS",
  DELETE_SUCCESS: "ADMIN_DELETE_PRO_SUCCESS",
}

export interface IPro {
  id: number;
  created_at: string;
  updated_at: string;
  context: string;
  priority: number | null;
  option_criteria: number;
}

interface NormalizedEntities {
  [key: string]: IPro;
}

export interface IState {
  entities: NormalizedEntities;
  ids: string[];
  count: number;
  loading: boolean;
  error: any;
}
