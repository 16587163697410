const sharedSettings = {
  project_plan: { editable: true, type: 'project-plan-select', rules: [{ required: true }] },
  progress: { editable: true, type: 'number', rules: [{ required: true }] },

  start: { editable: true, type: 'number' },
  current: { editable: true, type: 'number' },
  target: { editable: true, type: 'number' },

  label: { editable: true, type: 'select', rules: [{ options: [
    { value: 'currency', label: 'Currency' },
    { value: 'distance', label: 'Distance' },
    { value: 'energy', label: 'Energy' },
    { value: 'height', label: 'Height' },
    { value: 'time', label: 'Time' },
    { value: 'volume', label: 'Volume' },
    { value: 'weight', label: 'Weight' },
    { value: 'count', label: 'Count' },
    { value: 'custom', label: 'Custom' },
    { value: 'scale', label: 'Scale' },
  ] }] },

  distance_unit: { editable: true, type: 'select', rules: [{ options: [
    { value: "" },
    { value: 'km' },
    { value: 'miles' },
  ] }] },

  energy_unit: { editable: true, type: 'select', rules: [{ options: [
    { value: "" },
    { value: 'kcal' },
    { value: 'kj' },
  ] }] },

  height_unit: { editable: true, type: 'select', rules: [{ options: [
    { value: "" },
    { value: 'ft' },
    { value: 'cm' },
    { value: 'inches' },
  ] }] },

  time_unit: { editable: true, type: 'select', rules: [{ options: [
    { value: "" },
    { value: 'hours' },
    { value: 'minutes' },
  ] }] },

  volume_unit: { editable: true, type: 'select', rules: [{ options: [
    { value: "" },
    { value: 'cups' },
    { value: 'fl oz' },
    { value: 'ml' },
  ] }] },

  weight_unit: { editable: true, type: 'select', rules: [{ options: [
    { value: "" },
    { value: 'kg' },
    { value: 'lbs' },
  ] }] },

  count_unit: { editable: true, type: 'select', rules: [{ options: [
    { value: "" },
    { value: '1-10' },
    { value: '#' },
  ] }] },

  currency_unit: { editable: true, type: 'select', rules: [{ options: [
    { value: "" },
    { value: '€' },
    { value: '£' },
    { value: '$' },
  ] }] },

  scale_unit: { editable: true, type: 'select', rules: [{ options: [
    { value: "" },
    { value: '%' },
  ] }] },

  custom_unit: { editable: true, type: 'input' },

  name: { hidden: true },
  start_change_timestamp: { hidden: true },
}

export const settings = {
  id: { label: "ID" },
  ...sharedSettings,

  created_at: { editable: false, type: 'datetime', rules: [{ type: 'object' as const }] },
  updated_at: { editable: false, type: 'datetime', rules: [{ type: 'object' as const }] },  
};

export const initialSettings = {
  id: { hidden: true },
  ...sharedSettings,

  created_at: { hidden: true },
  updated_at: { hidden: true },
}

export const initialValues = {
  project_plan: null,
  progress: 0,

  start: 0,
  current: 0,
  target: 0,

  label: '',
  distance_unit: '',
  energy_unit: '',
  height_unit: '',
  time_unit: '',
  volume_unit: '',
  weight_unit: '',
  count_unit: '',
  currency_unit: '',
  scale_unit: '',
  custom_unit: '',

  name: '',
  start_change_timestamp: '00:00:00',
}
