export const ActionTypes = {
  REQUEST: "ADMIN_FETCH_CRITERIA_REQUEST",
  SUCCESS: "ADMIN_FETCH_CRITERIA_SUCCESS",
  FAILURE: "ADMIN_FETCH_CRITERIA_FAILURE",

  SUCCESS_SINGLE: "ADMIN_FETCH_CRITERION_SUCCESS",
  UPDATE_SUCCESS: "ADMIN_UPDATE_CRITERION_SUCCESS",
  CREATE_SUCCESS: "ADMIN_CREATE_CRITERION_SUCCESS",
  DELETE_SUCCESS: "ADMIN_DELETE_CRITERION_SUCCESS",
}

export interface ICriterion {
  id: number;
  created_at: string;
  updated_at: string;
  title: string;
  description: string;
  weight: number;
  priority: number;
  rubric: string;
  active: boolean;
  decision: number;
}

interface NormalizedEntities {
  [key: string]: ICriterion;
}

export interface IState {
  entities: NormalizedEntities;
  ids: string[];
  count: number;
  loading: boolean;
  error: any;
}
