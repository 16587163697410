import { useEffect } from 'react';
import { Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from "react-router-dom";

import FilterableTable from 'components/Admin/ObjectTable';

import { fetchOptionCriteria } from 'Redux/Admin/DecisionManager/OptionCriteria/action';

import rootReducer from 'Redux/root-reducer';

import type { TableColumnsType } from 'antd';
import type { FilterConfig } from '@types';
import type { IOptionCriterion } from 'Redux/Admin/DecisionManager/OptionCriteria/type';

import { ADMIN_PATH } from "utils/routes";

type RootState = ReturnType<typeof rootReducer>;

const columns: TableColumnsType<IOptionCriterion> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 120,
    render: (_, record) => (
      <Space size="middle">
        <Link to={`${ADMIN_PATH}/decision-manager/option-criteria/${record.id}`}>{record.id}</Link>
      </Space>
    ),
  },
  {
    title: 'Facts',
    dataIndex: 'facts',
    key: 'facts',
    width: 280,
  },
  {
    title: 'Option',
    dataIndex: 'option',
    key: 'option',
    width: 120,
  },
  {
    title: 'Criteria',
    dataIndex: 'criteria',
    key: 'criteria',
    width: 120,
  },
];

const OptionCriteria = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const option = params.get('option');
  const criteria = params.get('criteria');
  const pageValue = parseInt(params.get('page') || '1');

  const { entities, ids, count, loading } = useSelector((state: RootState) => state.fetchOptionCriteria);

  useEffect(() => {
    dispatch(fetchOptionCriteria({ option, criteria, offset: (pageValue - 1) * 50, limit: 50 }, () => {}));
  }, [option, criteria, pageValue, dispatch]);

  // Build filter configs for this page
  const filterConfigs: FilterConfig[] = [
    {
      type: 'option-select',
      paramName: 'option',
      placeholder: 'Select option...',
      label: 'Option'
    },
    {
      type: 'criterion-select',
      paramName: 'criteria',
      placeholder: 'Select criterion...',
      label: 'Criterion'
    },
  ];

  const dataItems = ids.map(id => entities[id]);

  return (
    <FilterableTable<IOptionCriterion>
      columns={columns}
      data={dataItems}
      loading={loading}
      totalItems={count}
      createLink={`${ADMIN_PATH}/decision-manager/option-criteria/new`}
      filters={filterConfigs}
    />
  );
}

export default OptionCriteria;
