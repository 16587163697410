const sharedSettings = {
  option: { editable: true, type: 'option-select', rules: [{ required: true }] },
  criteria: { editable: true, type: 'criterion-select', rules: [{ required: true }] },

  score: { editable: true, type: 'number' },
  second_stage_score: { editable: true, type: 'number' },

  facts: { editable: true, type: 'textarea' },

  is_blank: { editable: true, type: 'switch' },
  is_na: { editable: true, label: "Is N/A", type: 'switch' },
  second_stage_is_blank: { editable: true, type: 'switch' },
  second_stage_is_na: { editable: true, label: "Second Stage Is N/A", type: 'switch' },
}

export const settings = {
  id: { label: "ID" },
  ...sharedSettings,

  created_at: { editable: false, type: 'datetime', rules: [{ type: 'object' as const }] },
  updated_at: { editable: false, type: 'datetime', rules: [{ type: 'object' as const }] },  
};

export const initialSettings = {
  id: { hidden: true },
  ...sharedSettings,

  created_at: { hidden: true },
  updated_at: { hidden: true },
}

export const initialValues = {
  option: null,
  criteria: null,
  score: 0,
  second_stage_score: 0,
  facts: '',
  is_blank: false,
  is_na: false,
  second_stage_is_blank: false,
  second_stage_is_na: false,
};
