export const ActionTypes = {
  REQUEST: "ADMIN_FETCH_MASTER_PLANS_REQUEST",
  SUCCESS: "ADMIN_FETCH_MASTER_PLANS_SUCCESS",
  FAILURE: "ADMIN_FETCH_MASTER_PLANS_FAILURE",

  REQUEST_SINGLE: "ADMIN_FETCH_MASTER_PLAN_REQUEST",
  SUCCESS_SINGLE: "ADMIN_FETCH_MASTER_PLAN_SUCCESS",
  FAILURE_SINGLE: "ADMIN_FETCH_MASTER_PLAN_FAILURE",

  CREATE_REQUEST: "ADMIN_CREATE_MASTER_PLAN_REQUEST",
  CREATE_SUCCESS: "ADMIN_CREATE_MASTER_PLAN_SUCCESS",
  CREATE_FAILURE: "ADMIN_CREATE_MASTER_PLAN_FAILURE",

  UPDATE_REQUEST: "ADMIN_UPDATE_MASTER_PLAN_REQUEST",
  UPDATE_SUCCESS: "ADMIN_UPDATE_MASTER_PLAN_SUCCESS",
  UPDATE_FAILURE: "ADMIN_UPDATE_MASTER_PLAN_FAILURE",

  DELETE_REQUEST: "ADMIN_DELETE_MASTER_PLAN_REQUEST",
  DELETE_SUCCESS: "ADMIN_DELETE_MASTER_PLAN_SUCCESS",
  DELETE_FAILURE: "ADMIN_DELETE_MASTER_PLAN_FAILURE",
}

export interface IMasterPlan {
  id: string;
  created_at: string;
  updated_at: string;
  step: number;
  completed_at: string | null;
  status: string;
  is_private: boolean;
  premise: string;
  user: string;
  journey: string;
}

interface NormalizedEntities {
  [key: string]: IMasterPlan;
}

export interface IState {
  entities: NormalizedEntities;
  ids: string[];
  count: number;
  loading: boolean;
  error: any;
}
