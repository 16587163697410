const sharedSettings = {
  user: { editable: true, type: 'project-user-select', rules: [{ required: true }] },

  problem: { editable: true, type: 'input', rules: [{ required: true }] },
  description: { editable: true, type: 'textarea', rules: [{ required: true }] },

  startline: { editable: false, type: 'datetime', rules: [{ type: 'object' as const }] },
  deadline: { editable: false, type: 'datetime', rules: [{ type: 'object' as const }] },

  purpose: { editable: true, type: 'textarea' },
  scoring_rubric: { editable: true, type: 'textarea' },

  duration: { editable: true, type: 'number' },
  priority: { editable: true, type: 'number' },

  is_new: { editable: true, type: 'switch' },
  is_sample: { editable: true, type: 'switch' },
  allocator: { editable: true, type: 'switch' },
}

export const settings = {
  id: { label: "ID" },
  ...sharedSettings,

  created_at: { editable: false, type: 'datetime', rules: [{ type: 'object' as const }] },
  updated_at: { editable: false, type: 'datetime', rules: [{ type: 'object' as const }] },  
};

export const initialSettings = {
  id: { hidden: true },
  ...sharedSettings,

  created_at: { hidden: true },
  updated_at: { hidden: true },
}

export const initialValues = {
  user: null,
  problem: '',
  description: '',
  startline: null,
  deadline: null,
  purpose: '',
  scoring_rubric: '',
  duration: 0,
  priority: 0,
  is_new: true,
  is_sample: false,
  allocator: false,
};
