export const ActionTypes = {
  REQUEST: "ADMIN_FETCH_USER_ACTIONS_REQUEST",
  SUCCESS: "ADMIN_FETCH_USER_ACTIONS_SUCCESS",
  FAILURE: "ADMIN_FETCH_USER_ACTIONS_FAILURE",

  REQUEST_SINGLE: "ADMIN_FETCH_USER_ACTION_REQUEST",
  SUCCESS_SINGLE: "ADMIN_FETCH_USER_ACTION_SUCCESS",
  FAILURE_SINGLE: "ADMIN_FETCH_USER_ACTION_FAILURE",

  CREATE_REQUEST: "ADMIN_CREATE_USER_ACTION_REQUEST",
  CREATE_SUCCESS: "ADMIN_CREATE_USER_ACTION_SUCCESS",
  CREATE_FAILURE: "ADMIN_CREATE_USER_ACTION_FAILURE",

  UPDATE_REQUEST: "ADMIN_UPDATE_USER_ACTION_REQUEST",
  UPDATE_SUCCESS: "ADMIN_UPDATE_USER_ACTION_SUCCESS",
  UPDATE_FAILURE: "ADMIN_UPDATE_USER_ACTION_FAILURE",

  DELETE_REQUEST: "ADMIN_DELETE_USER_ACTION_REQUEST",
  DELETE_SUCCESS: "ADMIN_DELETE_USER_ACTION_SUCCESS",
  DELETE_FAILURE: "ADMIN_DELETE_USER_ACTION_FAILURE",
}

export interface IUserAction {
  id: string;
  created_at: string;
  updated_at: string;
  name: string;
  context: string;
  startline: string | null;
  deadline: string | null;
  habit: boolean;
  accountability: string;
  priority: number;
  type: string;
  completed: boolean;
  weight: string;
  user: string;
  goal: string;
}

interface NormalizedEntities {
  [key: string]: IUserAction;
}

export interface IState {
  entities: NormalizedEntities;
  ids: string[];
  count: number;
  loading: boolean;
  error: any;
}
