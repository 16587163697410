import { useEffect } from 'react';
import { Badge, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from "react-router-dom";

import FilterableTable from 'components/Admin/ObjectTable';

import { fetchDomains } from 'Redux/Admin/GoalsManager/Domains/action';

import rootReducer from 'Redux/root-reducer';

import type { TableColumnsType } from 'antd';
import type { IDomain } from 'Redux/Admin/GoalsManager/Domains/type';

import { ADMIN_PATH } from "utils/routes";

type RootState = ReturnType<typeof rootReducer>;

const columns: TableColumnsType<IDomain> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 280,
    render: (_, record) => (
      <Space size="middle">
        <Link to={`${ADMIN_PATH}/goals/system/domains/${record.id}`}>{record.id}</Link>
      </Space>
    ),
  },
  {
    title: 'Name',
    dataIndex: 'name',
    width: 220
  },
  {
    title: 'Order',
    dataIndex: 'order',
    width: 120
  },
  {
    title: 'Is Visible',
    dataIndex: 'visible',
    width: 120,
    render: (_, record) => (
      <Badge status={record.visible ? 'success' : 'error'} />
    )
  },
  {
    title: 'Is Arootah only',
    dataIndex: 'arootah_only',
    width: 120,
    render: (_, record) => (
      <Badge status={record.arootah_only ? 'success' : 'error'} />
    )
  },
];

const Domains = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const searchValue = params.get('search');
  const pageValue = parseInt(params.get('page') || '1');

  const { entities, ids, count, loading } = useSelector((state: RootState) => state.fetchDomains);

  useEffect(() => {
    dispatch(fetchDomains({ search: searchValue, offset: (pageValue - 1) * 50, limit: 50 }, () => {}));
  }, [searchValue, pageValue, dispatch]);

  const dataItems = ids.map(id => entities[id]);

  return (
    <FilterableTable<IDomain>
      columns={columns}
      data={dataItems}
      loading={loading}
      totalItems={count}
      createLink={`${ADMIN_PATH}/goals/system/domains/new`}
    />
  );
}

export default Domains;
