const sharedSettings = {
  name: { editable: true, type: 'input' },
  context: { editable: true, type: 'input' },

  startline: { editable: true, type: 'datetime', rules: [{ type: 'object' as const }] },
  deadline: { editable: true, type: 'datetime', rules: [{ type: 'object' as const }] },

  priority: { editable: true, type: 'number' },
  habit: { editable: true, type: 'switch', label: "Is Habit" },
  completed: { editable: true, type: 'switch', label: "Is Completed" },
  accountability: { editable: true, type: 'input' },
  type: { editable: true, type: 'input' },
  weight: { editable: true, type: 'input' },

  user: { editable: true, type: 'user-select', rules: [{ required: true }] },
  goal: { editable: true, type: 'goal-select', rules: [{ required: true }] },
}

export const settings = {
  id: { label: "ID" },

  ...sharedSettings,

  created_at: { editable: true, type: 'datetime', rules: [{ type: 'object' as const }] },
  updated_at: { editable: true, type: 'datetime', rules: [{ type: 'object' as const }] },
};

export const initialSettings = {
  id: { hidden: true },
  created_at: { hidden: true },
  updated_at: { hidden: true },

  ...sharedSettings,
}

export const initialValues = {
  name: "",
  context: "",

  startline: null,
  deadline: null,

  priority: 0,
  habit: false,
  completed: false,
  accountability: "",
  type: "",
  weight: "",

  user: "",
  goal: "",
};
