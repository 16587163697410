export const ActionTypes = {
  REQUEST: "ADMIN_FETCH_OPTIONS_REQUEST",
  SUCCESS: "ADMIN_FETCH_OPTIONS_SUCCESS",
  FAILURE: "ADMIN_FETCH_OPTIONS_FAILURE",

  SUCCESS_SINGLE: "ADMIN_FETCH_OPTION_SUCCESS",
  UPDATE_SUCCESS: "ADMIN_UPDATE_OPTION_SUCCESS",
  CREATE_SUCCESS: "ADMIN_CREATE_OPTION_SUCCESS",
  DELETE_SUCCESS: "ADMIN_DELETE_OPTION_SUCCESS",
}

export interface IOption {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  image: string | null;
  link: string | null;
  weighted_score: number;
  adjusted_score: number;
  second_stage_weighted_score: string;
  second_stage_adjusted_score: number;
  completed_criteria_percent: number;
  second_stage_completed_criteria_percent: number;
  notes: string;
  highlighted: boolean;
  shortlist: boolean;
  finalist: boolean;
  verdict: string;
  decision: number;
}

interface NormalizedEntities {
  [key: string]: IOption;
}

export interface IState {
  entities: NormalizedEntities;
  ids: string[];
  count: number;
  loading: boolean;
  error: any;
}
