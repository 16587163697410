import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Space } from 'antd';
import moment from 'moment';

import FilterableTable from 'components/Admin/ObjectTable';

import { fetchUserActions } from 'Redux/Admin/GoalsManager/UserActions/action';

import type { FilterConfig } from '@types';
import type { TableColumnsType } from 'antd';
import type { IUserAction } from 'Redux/Admin/GoalsManager/UserActions/type';

import rootReducer from 'Redux/root-reducer';

import { ADMIN_PATH } from "utils/routes";

type RootState = ReturnType<typeof rootReducer>;

const columns: TableColumnsType<IUserAction> = [
  {
    title: 'ID',
    dataIndex: 'id',
    width: 280,
    render: (_, record) => (
      <Space size="middle">
        <Link to={`${ADMIN_PATH}/goals/journeys/user-actions/${record.id}`}>{record.id}</Link>
      </Space>
    ),
  },
  {
    title: 'Name',
    dataIndex: 'context',
    width: 280,
  },
  {
    title: 'Goal',
    dataIndex: 'goal',
    width: 280,
    render: (_, record) => (
      <Space size="middle">
        <Link to={`${ADMIN_PATH}/goals/journeys/user-goals/${record.goal}`}>{record.goal}</Link>
      </Space>
    ),
  },
  {
    title: 'User',
    dataIndex: 'user',
    width: 280,
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (text) => moment(text).format("YYYY-MM-DD HH:mm:ss"),
    width: 180,
  },
];

const UserActions = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  // Extract query params
  const params = new URLSearchParams(location.search);

  const goalId = params.get('goal') || '';
  const userId = params.get('user') || '';
  const searchValue = params.get('search') || '';
  const pageValue = parseInt(params.get('page') || '1');
  const pageSizeValue = parseInt(params.get('size') || '50');

  // Redux state
  const { entities, ids, count, loading } = useSelector((state: RootState) => state.fetchUserActions);

  // Fetch master plans whenever relevant query params change
  useEffect(() => {
    dispatch(
      fetchUserActions({
        goal: goalId || undefined,
        user: userId || undefined,
        search: searchValue || undefined,
        offset: (pageValue - 1) * pageSizeValue,
        limit: pageSizeValue,
      }, 
      () => {})
    );
  }, [goalId, userId, searchValue, pageValue, pageSizeValue, dispatch]);

  // Build filter configs for this page
  const filterConfigs: FilterConfig[] = [
    {
      type: 'search',
      paramName: 'search',
      label: 'Search',
      placeholder: 'Search by name...'
    },
    {
      type: 'goal-select',
      paramName: 'goal',
      placeholder: 'Select goal...',
      label: 'Goal'
    },
    {
      type: 'user-select',
      paramName: 'user',
      placeholder: 'Select user...',
      label: 'User'
    },
  ];

  const dataItems = ids.map(id => entities[id]);

  return (
    <FilterableTable<IUserAction>
      columns={columns}
      data={dataItems}
      loading={loading}
      totalItems={count}
      filters={filterConfigs}
      createLink={`${ADMIN_PATH}/goals/journeys/user-actions/new`}
    />
  );
};

export default UserActions;
