import { CSSProperties } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';

import type { MenuProps } from 'antd';

import {
  ADMIN_PATH,
  ADMIN_PROJECT_PLANS_PATH,
  ADMIN_PROJECT_GOALS_PATH,
  ADMIN_PROJECT_ACTION_CATEGORIES_PATH,
  ADMIN_PROJECT_ACTION_ITEMS_PATH,
} from 'utils/routes';

const { Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

const siderStyle: CSSProperties = {
  overflow: 'auto',
  height: 'calc(100vh - 64px)',
  position: 'sticky',
  insetInlineStart: 0,
  top: 64,
  bottom: 0,
  scrollbarWidth: 'thin',
  scrollbarGutter: 'stable',
};

const items: MenuItem[] = [
  {
    key: 'general',
    label: 'General',
    type: 'group',
    children: [
      { key: 'users', label: <Link to={`${ADMIN_PATH}/users`}>Users</Link> },
    ],
  },
  
  {
    key: 'divider',
    label: 'Projects',
    type: 'group'
  },
  {
    key: 'goals',
    label: 'Goals Manager',
    children: [
      { key: 'journeys', label: "Journeys", type: 'group', children: [
        { key: 'masterplans', label: <Link to={`${ADMIN_PATH}/goals/journeys/masterplans`}>Masterplans</Link> },
        { key: 'user-areas', label: <Link to={`${ADMIN_PATH}/goals/journeys/user-areas`}>User Areas</Link> },
        { key: 'user-goals', label: <Link to={`${ADMIN_PATH}/goals/journeys/user-goals`}>User Goals</Link> },
        { key: 'user-actions', label: <Link to={`${ADMIN_PATH}/goals/journeys/user-actions`}>User Actions</Link> },
      ] },
      { key: 'system', label: "System", type: 'group', children: [
        { key: 'domains', label: <Link to={`${ADMIN_PATH}/goals/system/domains`}>Domains</Link> },
      ] },
    ],
  },
  {
    type: 'divider',
  },
  {
    key: 'project-manager',
    label: 'Project Manager',
    children: [
      { key: 'project-plans', label: <Link to={`${ADMIN_PROJECT_PLANS_PATH}`}>Project Plans</Link> },
      { key: 'project-goals', label: <Link to={`${ADMIN_PROJECT_GOALS_PATH}`}>Goals</Link> },
      { key: 'action-categories', label: <Link to={`${ADMIN_PROJECT_ACTION_CATEGORIES_PATH}`}>Action Categories</Link> },
      { key: 'action-items', label: <Link to={`${ADMIN_PROJECT_ACTION_ITEMS_PATH}`}>Action Items</Link> },
    ],
  },
  {
    type: 'divider',
  },
  {
    key: 'task-manager',
    label: 'Task Manager',
    children: [
      { key: 'tasks', label: <Link to={`${ADMIN_PATH}/task-manager/tasks`}>Tasks</Link> },
      { key: 'prioritized-activities', label: <Link to={`${ADMIN_PATH}/task-manager/prioritized-activities`}>Prioritized Activities</Link> },
      { key: 'focused-priorities', label: <Link to={`${ADMIN_PATH}/task-manager/focused-priorities`}>Focused Priorities</Link> },
    ],
  },
  {
    type: 'divider',
  },
  {
    key: 'decision-manager',
    label: 'Decision Manager',
    children: [
      { key: 'decisions', label: <Link to={`${ADMIN_PATH}/decision-manager/decisions`}>Decisions</Link> },
      { key: 'options', label: <Link to={`${ADMIN_PATH}/decision-manager/options`}>Options</Link> },
      { key: 'criteria', label: <Link to={`${ADMIN_PATH}/decision-manager/criteria`}>Criteria</Link> },
      { key: 'option-criteria', label: <Link to={`${ADMIN_PATH}/decision-manager/option-criteria`}>Option-Criteria</Link> },
      { key: 'pros', label: <Link to={`${ADMIN_PATH}/decision-manager/pros`}>Pros</Link> },
      { key: 'cons', label: <Link to={`${ADMIN_PATH}/decision-manager/cons`}>Cons</Link> },
    ],
  },
];

const Sidebar = () => {
  const { pathname } = useLocation();

  const activeKey = pathname.split('/')[2];
  const activeKey2 = pathname.split('/')[3];
  const activeKey3 = pathname.split('/')[4];

  return (
    <Sider style={siderStyle} width={200}>
      <Menu
        mode="inline"
        selectedKeys={[activeKey, activeKey2, activeKey3]}
        defaultOpenKeys={["goals"]}
        items={items}
        style={{ minHeight: `calc(100vh - 64px)`, paddingRight: 0 }}
      />
    </Sider>
  );
}

export default Sidebar;
