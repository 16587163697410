import type { SelectValue } from 'antd/lib/select';

type LabeledSelectProps<T> = {
  label?: string;
  paramName: string;
  currentValue: string;
  placeholder?: string;
  style?: React.CSSProperties;
  onChange: (val: T) => void;
  // `Component` is the actual "Select" (UserSelect, DomainSelect, etc.)
  // We assume it has the same signature for `value`, `onChange`, etc.
  Component: React.ComponentType<any>;
};

function LabeledSelect<T extends SelectValue>({
  label,
  paramName,
  currentValue,
  placeholder,
  style,
  onChange,
  Component
}: LabeledSelectProps<T>) {
  return (
    <div key={paramName}>
      {label && <div>{label}</div>}
      <Component
        allowClear
        value={currentValue || undefined}
        placeholder={placeholder || 'Select...'}
        onChange={onChange}
        style={style}
      />
    </div>
  );
}

export default LabeledSelect;
