import { BaseService } from "./base";
import { DMServices } from "./dm";
import { GMServices } from "./gm";

import { buildUrl } from "utils/utility";

export const checkAuth = () => BaseService.get("/user/check_auth/", false);
export const removeAuth = () => BaseService.get("user/remove_auth/");
export const register = (data: any) => BaseService.post("user/", data, false);
export const login = (data: any) => BaseService.post("user/login/", data, false);
export const logout = () => BaseService.get("user/logout/");
export const passwordResetLink = (data: any) => BaseService.post("user/send_reset_password_email/", data, false);
export const resetPassword = (data: any, token: string) => BaseService.post("user/reset_password/", data, true, token);
export const verifyOtp = (data: any) => BaseService.post("user/verify_otp/", data, false);
export const resendOtp = (data: any) => BaseService.post("user/resend_otp/", data, false);

// Admin
export const adminFetchUsers = async (filterParams: any = {}) => {
  const url = buildUrl('custom-admin/users/', filterParams);
  const response = await BaseService.get(url, true);
  
  // Extract the headers, including the "Count" property
  const { data, headers } = response;

  // If you need the "Count" header, you can access it like this:
  const count = headers['count'];

  // Return both the data and any additional info you need
  return {
    data,
    count, // return "Count" or any other header parameter you need
  };
};

export const adminFetchUser = (payload: string) => BaseService.get(`custom-admin/users/${payload}/`, true);
export const adminUpdateUser = (payload: FormData, id: string) => BaseService.patch(`custom-admin/users/${id}/`, payload, true);
export const adminDeleteUser = (payload: string) => BaseService.remove(`custom-admin/users/${payload}/`, true);

export const adminFetchDmUser = (payload: string) => DMServices.get(`custom-admin/users/?public_id=${payload}`, true);
export const adminUpdateDmUser = (payload: FormData, id: string) => DMServices.patch(`custom-admin/users/${id}/`, payload, true);

export const adminFetchGmUser = (payload: string) => GMServices.get(`custom-admin/users/?public_id=${payload}`, true);
export const adminUpdateGmUser = (payload: FormData, id: string) => GMServices.patch(`custom-admin/users/${id}/`, payload, true);

// Admin
// Domains
export const adminFetchDomains = (filterParams: any = {}) => {
  const url = buildUrl('custom-admin/system-domains/', filterParams);
  return GMServices.get(url, true);
}
export const adminFetchDomain = (id: string) => GMServices.get(`custom-admin/system-domains/${id}/`);
export const adminUpdateDomain = (payload: FormData, id: string) => GMServices.patch(`custom-admin/system-domains/${id}/`, payload, true);
export const adminDeleteDomain = (payload: string) => GMServices.remove(`custom-admin/system-domains/${payload}/`, true);

export const adminFetchSubdomains = (filterParams: any = {}) => {
  const url = buildUrl('custom-admin/system-subdomains/', filterParams);
  return GMServices.get(url, true);
}


// Master Plans
export const adminFetchMasterPlans = (filterParams: any = {}) => {
  const url = buildUrl('custom-admin/masterplans/', filterParams);
  return GMServices.get(url, true);
}
export const adminFetchMasterPlan = (id: string) => GMServices.get(`custom-admin/masterplans/${id}/`);
export const adminCreateMasterPlan = (payload: FormData) => GMServices.post(`custom-admin/masterplans/`, payload, true);
export const adminUpdateMasterPlan = (payload: FormData, id: string) => GMServices.patch(`custom-admin/masterplans/${id}/`, payload, true);
export const adminDeleteMasterPlan = (payload: string) => GMServices.remove(`custom-admin/masterplans/${payload}/`, true);

// User Areas
export const adminFetchUserAreas = (filterParams: any = {}) => {
  const url = buildUrl('custom-admin/user-areas/', filterParams);
  return GMServices.get(url, true);
}
export const adminFetchUserArea = (id: string) => GMServices.get(`custom-admin/user-areas/${id}/`);
export const adminCreateUserArea = (payload: FormData) => GMServices.post(`custom-admin/user-areas/`, payload, true);
export const adminUpdateUserArea = (payload: FormData, id: string) => GMServices.patch(`custom-admin/user-areas/${id}/`, payload, true);
export const adminDeleteUserArea = (payload: string) => GMServices.remove(`custom-admin/user-areas/${payload}/`, true);

// User Goals
export const adminFetchUserGoals = (filterParams: any = {}) => {
  const url = buildUrl('custom-admin/goals/', filterParams);
  return GMServices.get(url, true);
}
export const adminFetchUserGoal = (id: string) => GMServices.get(`custom-admin/goals/${id}/`);
export const adminCreateUserGoal = (payload: FormData) => GMServices.post(`custom-admin/goals/`, payload, true);
export const adminUpdateUserGoal = (payload: FormData, id: string) => GMServices.patch(`custom-admin/goals/${id}/`, payload, true);
export const adminDeleteUserGoal = (payload: string) => GMServices.remove(`custom-admin/user-goals/${payload}/`, true);

// User Actions
export const adminFetchUserActions = (filterParams: any = {}) => {
  const url = buildUrl('custom-admin/actions/', filterParams);
  return GMServices.get(url, true);
}
export const adminFetchUserAction = (id: string) => GMServices.get(`custom-admin/user-actions/${id}/`);
export const adminCreateUserAction = (payload: FormData) => GMServices.post(`custom-admin/user-actions/`, payload, true);
export const adminUpdateUserAction = (payload: FormData, id: string) => GMServices.patch(`custom-admin/user-actions/${id}/`, payload, true);
export const adminDeleteUserAction = (payload: string) => GMServices.remove(`custom-admin/user-actions/${payload}/`, true);


// Project Users
export const adminFetchProjectUsers = (filterParams: any = {}) => {
  const url = buildUrl('custom-admin/users/', filterParams);
  return DMServices.get(url, true);
}

// Project Plans
export const adminFetchProjectPlans = (filterParams: any = {}) => {
  const url = buildUrl('custom-admin/project-plans/', filterParams);
  return DMServices.get(url, true);
}
export const adminFetchProjectPlan = (id: string) => DMServices.get(`custom-admin/project-plans/${id}/`);
export const adminUpdateProjectPlan = (payload: FormData, id: string) => DMServices.patch(`custom-admin/project-plans/${id}/`, payload, true);
export const adminCreateProjectPlan = (payload: FormData) => DMServices.post(`custom-admin/project-plans/`, payload, true);
export const adminDeleteProjectPlan = (payload: string) => DMServices.remove(`custom-admin/project-plans/${payload}/`, true);

// Project Goals
export const adminFetchProjectGoals = (filterParams: any = {}) => {
  const url = buildUrl('custom-admin/goals/', filterParams);
  return DMServices.get(url, true);
}
export const adminFetchProjectGoal = (id: string) => DMServices.get(`custom-admin/goals/${id}/`);
export const adminUpdateProjectGoal = (payload: FormData, id: string) => DMServices.patch(`custom-admin/goals/${id}/`, payload, true);
export const adminCreateProjectGoal = (payload: FormData) => DMServices.post(`custom-admin/goals/`, payload, true);
export const adminDeleteProjectGoal = (payload: string) => DMServices.remove(`custom-admin/goals/${payload}/`, true);

// Action Categories
export const adminFetchActionCategories = (filterParams: any = {}) => {
  const url = buildUrl('custom-admin/action-categories/', filterParams);
  return DMServices.get(url, true);
}
export const adminFetchActionCategory = (id: string) => DMServices.get(`custom-admin/action-categories/${id}/`);
export const adminUpdateActionCategory = (payload: FormData, id: string) => DMServices.patch(`custom-admin/action-categories/${id}/`, payload, true);
export const adminCreateActionCategory = (payload: FormData) => DMServices.post(`custom-admin/action-categories/`, payload, true);
export const adminDeleteActionCategory = (payload: string) => DMServices.remove(`custom-admin/action-categories/${payload}/`, true);

// Action Items
export const adminFetchActionItems = (filterParams: any = {}) => {
  const url = buildUrl('custom-admin/action-items/', filterParams);
  return DMServices.get(url, true);
}
export const adminFetchActionItem = (id: string) => DMServices.get(`custom-admin/action-items/${id}/`);
export const adminUpdateActionItem = (payload: FormData, id: string) => DMServices.patch(`custom-admin/action-items/${id}/`, payload, true);
export const adminCreateActionItem = (payload: FormData) => DMServices.post(`custom-admin/action-items/`, payload, true);
export const adminDeleteActionItem = (payload: string) => DMServices.remove(`custom-admin/action-items/${payload}/`, true);

// Task Manager
// Action Items
export const adminFetchTasks = (filterParams: any = {}) => {
  const url = buildUrl('custom-admin/tasks/', filterParams);
  return GMServices.get(url, true);
}
export const adminFetchTask = (id: string) => GMServices.get(`custom-admin/tasks/${id}/`);
export const adminUpdateTask = (payload: FormData, id: string) => GMServices.patch(`custom-admin/tasks/${id}/`, payload, true);
export const adminCreateTask = (payload: FormData) => GMServices.post(`custom-admin/tasks/`, payload, true);
export const adminDeleteTask = (payload: string) => GMServices.remove(`custom-admin/tasks/${payload}/`, true);

// Prioritized Activities
export const adminFetchPrioritizedActivities = (filterParams: any = {}) => {
  const url = buildUrl('custom-admin/prioritized-activities/', filterParams);
  return GMServices.get(url, true);
}
export const adminFetchPrioritizedActivity = (id: string) => GMServices.get(`custom-admin/prioritized-activities/${id}/`);

export const adminFetchFocusedPriorities = (filterParams: any = {}) => {
  const url = buildUrl('custom-admin/focused-priorities/', filterParams);
  return GMServices.get(url, true);
}
export const adminFetchFocusedPriority = (id: string) => GMServices.get(`custom-admin/focused-priorities/${id}/`);

// Decision Manager
// 
// Decisions
export const adminFetchDecisions = (filterParams: any = {}) => {
  const url = buildUrl('custom-admin/decisions/', filterParams);
  return DMServices.get(url, true);
}
export const adminFetchDecision = (id: string) => DMServices.get(`custom-admin/decisions/${id}/`);
export const adminUpdateDecision = (payload: FormData, id: string) => DMServices.patch(`custom-admin/decisions/${id}/`, payload, true);
export const adminCreateDecision = (payload: FormData) => DMServices.post(`custom-admin/decisions/`, payload, true);
export const adminDeleteDecision = (payload: string) => DMServices.remove(`custom-admin/decisions/${payload}/`, true);

// Options
export const adminFetchOptions = (filterParams: any = {}) => {
  const url = buildUrl('custom-admin/options/', filterParams);
  return DMServices.get(url, true);
}
export const adminFetchOption = (id: string) => DMServices.get(`custom-admin/options/${id}/`);
export const adminUpdateOption = (payload: FormData, id: string) => DMServices.patch(`custom-admin/options/${id}/`, payload, true);
export const adminCreateOption = (payload: FormData) => DMServices.post(`custom-admin/options/`, payload, true);
export const adminDeleteOption = (payload: string) => DMServices.remove(`custom-admin/options/${payload}/`, true);

// Criteria
export const adminFetchCriteria = (filterParams: any = {}) => {
  const url = buildUrl('custom-admin/criteria/', filterParams);
  return DMServices.get(url, true);
}
export const adminFetchCriterion = (id: string) => DMServices.get(`custom-admin/criteria/${id}/`);
export const adminUpdateCriterion = (payload: FormData, id: string) => DMServices.patch(`custom-admin/criteria/${id}/`, payload, true);
export const adminCreateCriterion = (payload: FormData) => DMServices.post(`custom-admin/criteria/`, payload, true);
export const adminDeleteCriterion = (payload: string) => DMServices.remove(`custom-admin/criteria/${payload}/`, true);

// Option-Criteria
export const adminFetchOptionCriteria = (filterParams: any = {}) => {
  const url = buildUrl('custom-admin/option-criteria/', filterParams);
  return DMServices.get(url, true);
}
export const adminFetchOptionCriterion = (id: string) => DMServices.get(`custom-admin/option-criteria/${id}/`);
export const adminUpdateOptionCriterion = (payload: FormData, id: string) => DMServices.patch(`custom-admin/option-criteria/${id}/`, payload, true);
export const adminCreateOptionCriterion = (payload: FormData) => DMServices.post(`custom-admin/option-criteria/`, payload, true);
export const adminDeleteOptionCriterion = (payload: string) => DMServices.remove(`custom-admin/option-criteria/${payload}/`, true);

// Pros
export const adminFetchPros = (filterParams: any = {}) => {
  const url = buildUrl('custom-admin/pros/', filterParams);
  return DMServices.get(url, true);
}
export const adminFetchPro = (id: string) => DMServices.get(`custom-admin/pros/${id}/`);
export const adminUpdatePro = (payload: FormData, id: string) => DMServices.patch(`custom-admin/pros/${id}/`, payload, true);
export const adminCreatePro = (payload: FormData) => DMServices.post(`custom-admin/pros/`, payload, true);
export const adminDeletePro = (payload: string) => DMServices.remove(`custom-admin/pros/${payload}/`, true);

// Cons
export const adminFetchCons = (filterParams: any = {}) => {
  const url = buildUrl('custom-admin/cons/', filterParams);
  return DMServices.get(url, true);
}
export const adminFetchCon = (id: string) => DMServices.get(`custom-admin/cons/${id}/`);
export const adminUpdateCon = (payload: FormData, id: string) => DMServices.patch(`custom-admin/cons/${id}/`, payload, true);
export const adminCreateCon = (payload: FormData) => DMServices.post(`custom-admin/cons/`, payload, true);
export const adminDeleteCon = (payload: string) => DMServices.remove(`custom-admin/cons/${payload}/`, true);
