export const ActionTypes = {
  REQUEST: "ADMIN_FETCH_PRIORITIZED_ACTIVITY_REQUEST",
  SUCCESS: "ADMIN_FETCH_PRIORITIZED_ACTIVITY_SUCCESS",
  FAILURE: "ADMIN_FETCH_PRIORITIZED_ACTIVITY_FAILURE",

  SUCCESS_SINGLE: "ADMIN_FETCH_TASK_SUCCESS",
}

export interface IPrioritizedActivity {
  id: string;
  created_at: string;
  updated_at: string;
  direct_impact: string;
  indirect_impact: string;
  indirect_impact_completed: boolean;
  goal_weight: string;
  activity_weight: string;
  startline: string | null;
  deadline: string | null;
  starting_urgency: number;
  completion_urgency: number;
  urgency_score: string;
  starting_practical_classification: string;
  completion_practical_classification: string | null;
  delegation_time: number;
  management_time: number;
  total_time: number;
  estimated_weekly_duration: number;
  duration_completed: boolean;
  time_percent: string;
  time_score: string;
  duration_score: number;
  calculated_priority: string;
  user: string;
  action: string;
}

interface NormalizedEntities {
  [key: string]: IPrioritizedActivity;
}

export interface IState {
  entities: NormalizedEntities;
  ids: string[];
  count: number;
  loading: boolean;
  error: any;
}
