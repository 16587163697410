import Notify from "utils/notify";

import {
  adminFetchOptions,
  adminFetchOption,
  adminCreateOption,
  adminUpdateOption,
  adminDeleteOption
} from "api";

import { ActionTypes, IOption } from "./type";

export const fetchOptions = (payload: any, callback?: () => any) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.REQUEST });

    const { data } = await adminFetchOptions(payload);

    if (Array.isArray(data.results)) {
      dispatch({
        type: ActionTypes.SUCCESS,
        payload: data
      });
    }
    else {
      dispatch({
        type: ActionTypes.FAILURE,
        payload: data
      });

      Notify.error(data, true);
    }

    if (callback)
      callback();
  } catch (error) {
    console.error(error);

    dispatch({
      type: ActionTypes.FAILURE,
      payload: error
    });

    Notify.error(error, true);
  }
};

export const fetchOption = (payload: any, callback?: () => any) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.REQUEST });

    const { data } = await adminFetchOption(payload);

    dispatch({
      type: ActionTypes.SUCCESS_SINGLE,
      payload: data
    });

    if (callback)
      callback();
  } catch (error) {
    console.error(error);

    dispatch({
      type: ActionTypes.FAILURE,
      payload: error
    });

    Notify.error(error, true);
  }
};

export const createOption = (payload: any, callback?: (data: IOption) => any) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.REQUEST });

    const { data } = await adminCreateOption(payload);

    dispatch({
      type: ActionTypes.CREATE_SUCCESS,
      payload: data
    });

    Notify.success('Option created successfully!');

    if (callback)
      callback(data);
  } catch (error: any) {
    console.error(error);

    dispatch({
      type: ActionTypes.FAILURE,
      payload: error?.response?.data
    });

    Notify.error(error, true);
  }
};

export const updateOption = (payload: any, id: string, callback?: () => any) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.REQUEST });

    const { data } = await adminUpdateOption(payload, id);

    dispatch({
      type: ActionTypes.UPDATE_SUCCESS,
      payload: data
    });

    Notify.success('Option updated successfully!');

    if (callback)
      callback();
  } catch (error: any) {
    console.error(error);

    dispatch({
      type: ActionTypes.FAILURE,
      payload: error?.response?.data
    });

    Notify.error(error, true);
  }
};

export const deleteOption = (id: string, callback?: () => any) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.REQUEST });

    await adminDeleteOption(id);

    dispatch({
      type: ActionTypes.DELETE_SUCCESS,
      payload: id
    });

    Notify.success('Option deleted successfully!');

    if (callback)
      callback();
  } catch (error) {
    console.error(error);

    dispatch({
      type: ActionTypes.FAILURE,
      payload: error
    });

    Notify.error(error, true);
  }
};
