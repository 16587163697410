import { IActionCategory } from "../ActionCategories/type";

export const ActionTypes = {
  REQUEST: "ADMIN_FETCH_ACTION_ITEMS_REQUEST",
  SUCCESS: "ADMIN_FETCH_ACTION_ITEMS_SUCCESS",
  FAILURE: "ADMIN_FETCH_ACTION_ITEMS_FAILURE",

  REQUEST_SINGLE: "ADMIN_FETCH_ACTION_ITEM_REQUEST",
  SUCCESS_SINGLE: "ADMIN_FETCH_ACTION_ITEM_SUCCESS",
  FAILURE_SINGLE: "ADMIN_FETCH_ACTION_ITEM_FAILURE",

  UPDATE_REQUEST: "ADMIN_UPDATE_ACTION_ITEM_REQUEST",
  UPDATE_SUCCESS: "ADMIN_UPDATE_ACTION_ITEM_SUCCESS",
  UPDATE_FAILURE: "ADMIN_UPDATE_ACTION_ITEM_FAILURE",

  CREATE_REQUEST: "ADMIN_CREATE_ACTION_ITEM_REQUEST",
  CREATE_SUCCESS: "ADMIN_CREATE_ACTION_ITEM_SUCCESS",
  CREATE_FAILURE: "ADMIN_CREATE_ACTION_ITEM_FAILURE",

  DELETE_REQUEST: "ADMIN_DELETE_ACTION_ITEM_REQUEST",
  DELETE_SUCCESS: "ADMIN_DELETE_ACTION_ITEM_SUCCESS",
  DELETE_FAILURE: "ADMIN_DELETE_ACTION_ITEM_FAILURE",
}

export interface IActionItem {
  id: number;
  category_name: string;
  criteria_tag: any[];
  project_plan_id: string;
  project_plan_name: string;
  start_grace_time_left: any | null;
  is_start_locked: boolean;
  start_date_grace_time_left: boolean;
  is_start_date_locked: boolean;
  end_date_grace_time_left: boolean;
  is_end_date_locked: boolean;
  category_details: IActionCategory;
  comments: any[];
  created_at: string;
  updated_at: string;
  category_progress: string;
  start: string;
  current: string;
  target: string;
  start_change_timestamp: string | null;
  start_line: string;
  dead_line: string;
  start_line_change_timestamp: string | null;
  end_line_change_timestamp: string | null;
  name: string;
  weight: number;
  duration_type: string;
  duration_start: string;
  duration_end: string;
  duration: string;
  order_id: number;
  plan_progress: number;
  status: number;
  progress_last_update: string;
  priority: any | null;
  manual_override: boolean;
  reminder_type: string;
  periodic_type: any | null;
  reminder_time: any | null;
  reminder_server_time: any | null;
  periodic_start_date: any | null;
  periodic_end_date: any | null;
  repeat_monday: any | null;
  repeat_tuesday: any | null;
  repeat_wednesday: any | null;
  repeat_thursday: any | null;
  repeat_friday: any | null;
  repeat_saturday: any | null;
  repeat_sunday: any | null;
  repeat_days_in_month: any | null;
  repetition_frequency: any | null;
  repetition_type: any | null;
  last_reminded_date: any | null;
  label: any | null;
  distance_unit: any | null;
  energy_unit: any | null;
  height_unit: any | null;
  time_unit: any | null;
  volume_unit: any | null;
  weight_unit: any | null;
  count_unit: any | null;
  scale_unit: any | null;
  currency_unit: any | null;
  description: any | null;
  category: number;
  responsible_person: number;
  custom_unit: any | null;
}

interface NormalizedEntities {
  [key: string]: IActionItem;
}

export interface IState {
  entities: NormalizedEntities;
  ids: string[];
  count: number;
  loading: boolean;
  error: any;
}
