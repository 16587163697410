const sharedSettings = {
  category_progress: { editable: true, type: 'number', rules: [{ required: true }] },

  start: { editable: true, type: 'number' },
  current: { editable: true, type: 'number' },
  target: { editable: true, type: 'number' },

  start_line: { editable: false, type: 'datetime', rules: [{ type: 'object' as const }] },
  dead_line: { editable: false, type: 'datetime', rules: [{ type: 'object' as const }] },
  
  created_by: { editable: true, type: 'project-action-category-select' },

  name: { editable: true, type: 'input', rules: [{ required: true }] },
  weight: { editable: true, type: 'number', rules: [{ required: true }] },

  duration_type: { editable: true, type: 'select', rules: [{ options: [
    { value: 'default' },
    { value: 'custom' },
  ] }] },

  duration_start: { editable: false, type: 'time', rules: [{ type: 'object' as const }] },
  duration_end: { editable: false, type: 'time', rules: [{ type: 'object' as const }] },

  order_id: { editable: true, type: 'number' },

  status: { editable: true, type: 'select', rules: [{ required: true, options: [
    { value: '1', label: 'Todo' },
    { value: '2', label: 'In Progress' },
    { value: '3', label: 'On Hold' },
    { value: '4', label: 'Completed' },
    { value: '5', label: 'Canceled' },
  ] }] },

  priority: { editable: true, type: 'select', rules: [{ options: [
    { value: null, label: '' },
    { value: '1', label: 'Low' },
    { value: '2', label: 'Medium' },
    { value: '3', label: 'High' },
  ] }] },

  manual_override: { editable: true, type: 'switch' },

  reminder_type: { editable: true, type: 'select', rules: [{ required: true, options: [
    { value: 'Off' },
    { value: 'Daily' },
    { value: 'Periodic' },
  ] }] },

  periodic_type: { editable: true, type: 'select', rules: [{ options: [
    { value: 'Weekly' },
    { value: 'Monthly' },
    { value: 'Custom' },
  ] }] },

  reminder_time: { editable: true, type: 'time', rules: [{ type: 'object' as const }] },
  reminder_server_time: { editable: true, type: 'time', rules: [{ type: 'object' as const }] },

  periodic_start_date: { editable: true, type: 'datetime', rules: [{ type: 'object' as const }] },
  periodic_end_date: { editable: true, type: 'datetime', rules: [{ type: 'object' as const }] },

  repeat_monday: { editable: true, type: 'select', rules: [{ options: [
    { value: 'unknown', label: "Unknown" },
    { value: 'true', label: "Yes" },
    { value: 'false', label: "No" },
  ] }] },

  repeat_tuesday: { editable: true, type: 'select', rules: [{ options: [
    { value: 'unknown', label: "Unknown" },
    { value: 'true', label: "Yes" },
    { value: 'false', label: "No" },
  ] }] },

  repeat_wednesday: { editable: true, type: 'select', rules: [{ options: [
    { value: 'unknown', label: "Unknown" },
    { value: 'true', label: "Yes" },
    { value: 'false', label: "No" },
  ] }] },

  repeat_thursday: { editable: true, type: 'select', rules: [{ options: [
    { value: 'unknown', label: "Unknown" },
    { value: 'true', label: "Yes" },
    { value: 'false', label: "No" },
  ] }] },

  repeat_friday: { editable: true, type: 'select', rules: [{ options: [
    { value: 'unknown', label: "Unknown" },
    { value: 'true', label: "Yes" },
    { value: 'false', label: "No" },
  ] }] },

  repeat_saturday: { editable: true, type: 'select', rules: [{ options: [
    { value: 'unknown', label: "Unknown" },
    { value: 'true', label: "Yes" },
    { value: 'false', label: "No" },
  ] }] },

  repeat_sunday: { editable: true, type: 'select', rules: [{ options: [
    { value: 'unknown', label: "Unknown" },
    { value: 'true', label: "Yes" },
    { value: 'false', label: "No" },
  ] }] },

  repeat_days_in_month: { editable: true, type: 'input' },
  repetition_frequency: { editable: true, type: 'number' },

  repetition_type: { editable: true, type: 'select', rules: [{ options: [
    { value: 'days' },
    { value: 'weeks' },
    { value: 'months' },
    { value: 'years' },
  ] }] },

  last_reminded_date: { editable: true, type: 'datetime', rules: [{ type: 'object' as const }] },

  label: { editable: true, type: 'select', rules: [{ options: [
    { value: 'currency', label: 'Currency' },
    { value: 'distance', label: 'Distance' },
    { value: 'energy', label: 'Energy' },
    { value: 'height', label: 'Height' },
    { value: 'time', label: 'Time' },
    { value: 'volume', label: 'Volume' },
    { value: 'weight', label: 'Weight' },
    { value: 'count', label: 'Count' },
    { value: 'custom', label: 'Custom' },
    { value: 'scale', label: 'Scale' },
  ] }] },

  distance_unit: { editable: true, type: 'select', rules: [{ options: [
    { value: "" },
    { value: 'km' },
    { value: 'miles' },
  ] }] },

  energy_unit: { editable: true, type: 'select', rules: [{ options: [
    { value: "" },
    { value: 'kcal' },
    { value: 'kj' },
  ] }] },

  height_unit: { editable: true, type: 'select', rules: [{ options: [
    { value: "" },
    { value: 'ft' },
    { value: 'cm' },
    { value: 'inches' },
  ] }] },

  time_unit: { editable: true, type: 'select', rules: [{ options: [
    { value: "" },
    { value: 'hours' },
    { value: 'minutes' },
  ] }] },

  volume_unit: { editable: true, type: 'select', rules: [{ options: [
    { value: "" },
    { value: 'cups' },
    { value: 'fl oz' },
    { value: 'ml' },
  ] }] },

  weight_unit: { editable: true, type: 'select', rules: [{ options: [
    { value: "" },
    { value: 'kg' },
    { value: 'lbs' },
  ] }] },

  count_unit: { editable: true, type: 'select', rules: [{ options: [
    { value: "" },
    { value: '1-10' },
    { value: '#' },
  ] }] },

  currency_unit: { editable: true, type: 'select', rules: [{ options: [
    { value: "" },
    { value: '€' },
    { value: '£' },
    { value: '$' },
  ] }] },

  scale_unit: { editable: true, type: 'select', rules: [{ options: [
    { value: "" },
    { value: '%' },
  ] }] },

  custom_unit: { editable: true, type: 'input' },
}

export const settings = {
  id: { label: "ID" },
  ...sharedSettings,

  created_at: { editable: false, type: 'datetime', rules: [{ type: 'object' as const }] },
  updated_at: { editable: false, type: 'datetime', rules: [{ type: 'object' as const }] },

  category_name: { hidden: true },
  project_plan_id: { hidden: true },
  project_plan_name: { hidden: true },
  start_grace_time_left: { hidden: true },
  is_start_locked: { hidden: true },
  start_date_grace_time_left: { hidden: true },
  is_start_date_locked: { hidden: true },
  end_date_grace_time_left: { hidden: true },
  is_end_date_locked: { hidden: true },
  start_change_timestamp: { hidden: true },
  start_line_change_timestamp: { hidden: true },
  end_line_change_timestamp: { hidden: true },
  duration: { hidden: true },
  plan_progress: { hidden: true },
  progress_last_update: { hidden: true },
  description: { hidden: true },
  category: { hidden: true },
  responsible_person: { hidden: true },
};

export const initialSettings = {
  id: { hidden: true },
  ...sharedSettings,

  created_at: { hidden: true },
  updated_at: { hidden: true },
}

export const initialValues = {
  category_progress: 0,

  start: 0,
  current: 0,
  target: 0,

  start_line: null,
  dead_line: null,

  created_by: "",

  name: "",
  weight: 0,

  duration_type: "default",

  duration_start: "00:00:00",
  duration_end: "00:00:00",

  order_id: 0,

  status: "1",

  priority: null,

  manual_override: false,

  reminder_type: "Off",

  periodic_type: "Weekly",

  reminder_time: "00:00:00",
  reminder_server_time: "00:00:00",

  periodic_start_date: null,
  periodic_end_date: null,

  repeat_monday: "unknown",
  repeat_tuesday: "unknown",
  repeat_wednesday: "unknown",
  repeat_thursday: "unknown",
  repeat_friday: "unknown",
  repeat_saturday: "unknown",
  repeat_sunday: "unknown",

  repeat_days_in_month: "",
  repetition_frequency: 0,

  repetition_type: "days",

  last_reminded_date: null,

  label: "custom",

  distance_unit: "",
  energy_unit: "",
  height_unit: "",
  time_unit: "",
  volume_unit: "",
  weight_unit: "",
  count_unit: "",
  currency_unit: "",
  scale_unit: "",
  custom_unit: "",
};
