export const ActionTypes = {
  REQUEST: "ADMIN_FETCH_DECISIONS_REQUEST",
  SUCCESS: "ADMIN_FETCH_DECISIONS_SUCCESS",
  FAILURE: "ADMIN_FETCH_DECISIONS_FAILURE",

  SUCCESS_SINGLE: "ADMIN_FETCH_DECISION_SUCCESS",
  UPDATE_SUCCESS: "ADMIN_UPDATE_DECISION_SUCCESS",
  CREATE_SUCCESS: "ADMIN_CREATE_DECISION_SUCCESS",
  DELETE_SUCCESS: "ADMIN_DELETE_DECISION_SUCCESS",
}

export interface IDecision {
  id: number;
  created_at: string;
  updated_at: string;
  hashed_id: string;
  problem: string;
  description: string;
  purpose: string;
  scoring_rubric: string;
  startline: string;
  deadline: string;
  duration: number;
  priority: number;
  is_new: boolean;
  is_sample: boolean;
  allocator: boolean;
  shared_with_emails: string[];
  user: number;
  user_email: string;
  goal: any | null;
  final_option: any | null;
  original_decision: any | null;
  collaborators: number[];
}

interface NormalizedEntities {
  [key: string]: IDecision;
}

export interface IState {
  entities: NormalizedEntities;
  ids: string[];
  count: number;
  loading: boolean;
  error: any;
}
