import { Table, Input, Row, Col, Select, Space, Button, Flex, theme as antdTheme } from 'antd';
import type { TableColumnsType } from 'antd';
import { Link, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  FilterConfig,
  SearchFilterConfig,
  SelectFilterConfig
} from '@types'; // wherever you put your FilterConfig definitions

import {
  LabeledSelect,
  UserSelect,
  ProjectUserSelect,
  DomainSelect,
  ProjectPlanSelect,
  MasterplanSelect,
  AreaSelect,
  GoalSelect,
  ProjectActionCategorySelect,
  DecisionSelect,
  OptionSelect,
  CriterionSelect
} from '../Selectors';

const { Search } = Input;

const SELECT_MAP: Record<string, React.ComponentType<any>> = {
  'user-select': UserSelect,
  'project-user-select': ProjectUserSelect,
  'domain-select': DomainSelect,
  'masterplan-select': MasterplanSelect,
  'area-select': AreaSelect,
  'goal-select': GoalSelect,
  'project-plan-select': ProjectPlanSelect,
  'project-action-category-select': ProjectActionCategorySelect,
  'decision-select': DecisionSelect,
  'option-select': OptionSelect,
  'criterion-select': CriterionSelect,
  'option-criterion-select': CriterionSelect
};

interface FilterableTableProps<T> {
  /**
   * The columns configuration for antd Table
   */
  columns: TableColumnsType<T>;

  /**
   * The data to display in the table
   */
  data: T[];

  /**
   * Whether the table is loading
   */
  loading: boolean;

  /**
   * The total number of items (for pagination)
   */
  totalItems: number;

  /**
   * The link to navigate to when the user clicks "Create New"
   */
  createLink?: string;

  /**
   * An array of filter configurations that describe
   * which filters to show and how to handle them.
   */
  filters?: FilterConfig[];

  /**
   * Called whenever filters or pagination changes,
   * so parent can dispatch or fetch new data, etc.
   *
   * The argument is an object of all the current
   * query param values, e.g.
   * {
   *   page: 1,
   *   size: 20,
   *   search: 'hello',
   *   userId: '42'
   * }
   */
  onFiltersChange?: (query: Record<string, string>) => void;
}

function FilterableTable<T extends { id: number | string }>(props: FilterableTableProps<T>) {
  const {
    columns,
    data,
    loading,
    totalItems,
    createLink,
    filters = [],
    onFiltersChange
  } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const { useToken } = antdTheme;
  const { token: theme } = useToken();

  // -- Helper to parse page/size from query or default them:
  const pageParam = Number(searchParams.get('page') || '1');
  const sizeParam = Number(searchParams.get('size') || '50');

  // A function to read ALL query param values into an object
  const getAllQueryValues = (): Record<string, string> => {
    const result: Record<string, string> = {};

    // Convert the searchParams to an object:
    // includes standard table params:
    result.page = searchParams.get('page') || '1';
    result.size = searchParams.get('size') || '50';

    // includes all filter params:
    filters.forEach((filter) => {
      const value = searchParams.get(filter.paramName) || '';
      result[filter.paramName] = value;
    });
    return result;
  };

  // A function to update the query params in the URL (and notify parent)
  const updateParams = (newParams: Record<string, string>) => {
    // Construct a new URLSearchParams from existing
    const updated = new URLSearchParams(searchParams.toString());

    // Overwrite with the new values
    Object.entries(newParams).forEach(([key, value]) => {
      if (value) {
        updated.set(key, value);
      } else {
        updated.delete(key); // if empty, remove param
      }
    });

    // Update the URL
    setSearchParams(updated);

    // Notify parent
    if (onFiltersChange) {
      onFiltersChange(getAllQueryValues()); // or pass newParams directly if you prefer
    }
  };

  // Handler for table pagination changes
  const handleTableChange = (pagination: any) => {
    const { current, pageSize } = pagination;

    updateParams({
      page: String(current),
      size: String(pageSize)
    });
  };

  // Render each filter based on its config
  const renderFilter = (filter: FilterConfig) => {
    const currentValue = searchParams.get(filter.paramName) || '';

    switch (filter.type) {
      case 'search': {
        const f = filter as SearchFilterConfig;
        return (
          <div key={f.paramName}>
            {f.label && <div>{f.label}</div>}
            <Search
              placeholder={f.placeholder || 'Search...'}
              defaultValue={currentValue}
              enterButton
              onSearch={(val) => {
                updateParams({
                  [f.paramName]: val,
                  page: '1' // reset to first page
                });
              }}
              style={{ width: 240 }}
            />
          </div>
        );
      }

      case 'select': {
        const f = filter as SelectFilterConfig;
        return (
          <div key={f.paramName}>
            {f.label && <div>{f.label}</div>}
            <Select
              allowClear={f.allowClear}
              value={currentValue || undefined}
              placeholder={f.placeholder || 'Select...'}
              onChange={(val) => {
                updateParams({
                  [f.paramName]: val || '',
                  page: '1' // reset to first page
                });
              }}
              style={{ width: 240 }}
            >
              {f.options.map((opt: any) => (
                <Select.Option key={opt.value} value={opt.value}>
                  {opt.label}
                </Select.Option>
              ))}
            </Select>
          </div>
        );
      }

      case 'user-select':
      case 'project-user-select':
      case 'domain-select':
      case 'masterplan-select':
      case 'area-select':
      case 'goal-select':
      case 'project-plan-select':
      case 'project-action-category-select':
      case 'decision-select':
      case 'option-select':
      case 'criterion-select':
      case 'option-criterion-select':
      {
        const f = filter as SelectFilterConfig;
        const SelectComponent = SELECT_MAP[filter.type];

        if (!SelectComponent) {
          // In case there's no match in the map
          return null;
        }

        return (
          <LabeledSelect
            label={f.label}
            paramName={f.paramName}
            currentValue={currentValue}
            placeholder={f.placeholder}
            style={{ width: 320 }}
            onChange={(val: any) => {
              updateParams({
                [f.paramName]: val?.value || '',
                page: '1'
              });
            }}
            Component={SelectComponent}
          />
        );
      }

      default:
        return null;
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%', minWidth: 0 }}>
      <Flex justify="space-between" align="center" style={{ marginBottom: 16 }}>
        {/* Render filters */}
        <Row>
          <Col>
            <Space wrap size="middle">
              {filters.map((filter) => renderFilter(filter))}
            </Space>
          </Col>
        </Row>

        {createLink ? <Link to={createLink}>
          <Button>Add New</Button>
        </Link> : null}
      </Flex>

      {/* Render the table */}
      <Wrapper $bg={theme.colorBgContainer}>
        <Table<T>
          columns={columns}
          dataSource={data}
          loading={loading}
          rowKey={(record) => String(record.id)}
          pagination={{
            current: pageParam,
            pageSize: sizeParam,
            total: totalItems,
            showSizeChanger: true
          }}
          sticky={{
            offsetHeader: 64
          }}
          onChange={handleTableChange}
        />
      </Wrapper>
    </div>
  );
}

export const Wrapper = styled.div<{ $bg: string }>`
  min-width: 0;

  .ant-table-body {
    overflow: auto hidden;
  }

  .ant-pagination.ant-table-pagination {
    position: sticky;
    bottom: 0;
    background: ${({ $bg }) => $bg};
    z-index: 9;
    padding: 10px 0;
    border-top: 1px solid #f0f0f0;
    margin: 0;
  }

  .ant-table-cell {
    font-size: 12px;
  }
`;

export default FilterableTable;
