const sharedSettings = {
  name: { editable: true, type: 'input', rules: [{ required: true }] },
  description: { editable: true, type: 'input' },
  order: { editable: true, type: 'number', rules: [{ required: true }] },
  code: { editable: true, type: 'input' },
  type: { editable: true, type: 'select', rules: [{ required: true, options: [
    { value: 'Business', label: 'Business' },
    { value: 'Professional', label: 'Professional' },
    { value: 'Career', label: 'Career' },
  ] }] },

  visible: { editable: true, type: 'switch' },
  arootah_only: { editable: true, type: 'switch' },
}

export const settings = {
  id: { label: "ID" },

  ...sharedSettings,

  created_at: { editable: false, type: 'datetime', rules: [{ type: 'object' as const }] },
  updated_at: { editable: false, type: 'datetime', rules: [{ type: 'object' as const }] },
};

export const initialSettings = {
  id: { hidden: true },
  ...sharedSettings,
}

export const initialValues = {
  name: "",
  description: "",
  order: 0,
  code: "",
  type: "Business",
  visible: true,
  arootah_only: false
}
