const sharedSettings = {
  user: { editable: true, type: 'user-select', rules: [{ required: true }] },
  name: { editable: true, type: 'input', rules: [{ required: true }] },

  duration: { editable: true, type: 'number', rules: [{ required: true }] },
  estimated_duration: { editable: true, type: 'number' },
  deadline: { editable: true, type: 'datetime', rules: [{ type: 'object' as const }] },

  priority: { editable: true, type: 'select', rules: [{ options: [
    { value: 'Low' },
    { value: 'Medium' },
    { value: 'High' },
  ] }] },

  completed: { editable: true, type: 'switch' },
}

export const settings = {
  id: { label: "ID" },
  ...sharedSettings,

  created_at: { editable: false, type: 'datetime', rules: [{ type: 'object' as const }] },
  updated_at: { editable: false, type: 'datetime', rules: [{ type: 'object' as const }] },  
};

export const initialSettings = {
  id: { hidden: true },
  ...sharedSettings,

  created_at: { hidden: true },
  updated_at: { hidden: true },
}

export const initialValues = {
  user: null,
  name: '',
  duration: 0,
  estimated_duration: 0,
  deadline: null,
  priority: 'Low',
  completed: false,
  created_at: null,
  updated_at: null,
};
