import {
  Button,
  Space,
  DescriptionsProps,
  Spin,
  Card,
  Badge,
  Row,
  Col,
  Flex,
  TabsProps,
  Tabs,
  Dropdown,
  MenuProps,
  Modal
} from "antd";

import { useEffect, createContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useLocation, useNavigate, useParams } from "react-router-dom";

import { deleteUser, fetchUser } from "Redux/Admin/User/action";
import { fetchDMUser } from "Redux/Admin/DmUser/action";
import { fetchGMUser } from "Redux/Admin/GmUser/action";

import { RootState } from "Redux/store";
import { ADMIN_PATH } from "utils/routes";

import General from "./General";
import DecisionsManager from "./DecisionsManager";
import GoalsManager from "./GoalsManager";

import { DownOutlined } from "@ant-design/icons";

const User = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const pathname = location.pathname;
  const segments = pathname.split('/').filter(Boolean); // Filter to remove empty segments
  const lastSlug = segments[segments.length - 1];
  const activeKey = (lastSlug === '1' || lastSlug === '2' || lastSlug === '3') ? lastSlug : '1';

  // console.log('lastSlug', lastSlug);

  const { id } = useParams<{ id: string }>();

  const [modal, contextHolder] = Modal.useModal();

  const { data, loading } = useSelector(
    (state: RootState) => state.fetchUserReducer
  );

  /* const { data: dmData, loading: dmLoading } = useSelector(
    (state: RootState) => state.fetchDmUserReducer
  ); */

  const ReachableContext = createContext<string | null>(null);

  const modalConfig = {
    title: 'Delete the user?',
    content: <span>Are you sure you want to delete the user <b>{data ? data.email : ''}</b>?</span>,
    confirmLoading: true
  };

  if (!id) {
    navigate(`${ADMIN_PATH}/users`, { replace: true });
  }

  useEffect(() => {
    if (id) {
      dispatch(fetchUser(id));
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      dispatch(fetchDMUser(data.uuid));
      dispatch(fetchGMUser(data.uuid));
    }
  }, [data]);

  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: 'General',
      children: <General />,
    },
    {
      key: '2',
      label: 'Decision / Project Managers',
      children: <DecisionsManager />,
    },
    {
      key: '3',
      label: 'Goals / Time Managers',
      children: <GoalsManager />,
    },
  ];

  const menuItems: MenuProps['items'] = [
    /* {
      label: 'Manage Decisions',
      key: '1',
    },
    {
      label: 'Manage Goals',
      key: '2',
    }, */
    {
      label: 'Delete User',
      key: '3',
      danger: true,
    }
  ];

  const handleMenuClick: MenuProps['onClick'] = async (e) => {
    if (e.key === '3') {
      const confirmed = await modal.confirm(modalConfig);

      if (confirmed && id) {
        dispatch(deleteUser(id, () => {
          navigate(`${ADMIN_PATH}/users`, { replace: true });
        }));
      }
    }
  };

  const handleTabChange: TabsProps['onChange'] = (key) => {
    navigate(`${ADMIN_PATH}/users/${id}/${key}`);
  }

  const menuProps = {
    items: menuItems,
    onClick: handleMenuClick,
  };

  const userGoalId = (data.external_accounts[0]?.goal_setting_workbook) || null;

  return (
    <ReachableContext.Provider value="Light">
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <Row>
          <Col span={12}>
            <Flex
              gap="middle"
              align="center"
              justify="space-between"
              style={{ marginBottom: 16 }}
            >
              <Link to={`${ADMIN_PATH}/users`}>Back</Link>
            </Flex>

            <Card title="User Info" extra={
              <>
                {userGoalId ? <Link style={{ marginRight: 16 }} to={`${ADMIN_PATH}/goals/journeys/masterplans?user=${userGoalId}`}>View Masterplans</Link> : null}

                <Dropdown menu={menuProps} placement="bottom">
                  <Button>
                    <Space>
                      Manage User
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </>
            }>

              <Tabs activeKey={activeKey} items={tabItems} onChange={handleTabChange} />
            </Card>
          </Col>
        </Row>
      </div>

      {contextHolder}
    </ReachableContext.Provider>
  );
};

export default User;
