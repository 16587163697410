export const INDEX_PATH = '/';
export const OTP_PATH = '/otp';

export const SIGN_IN_PATH = '/sign-in';
export const SIGN_UP_PATH = '/sign-up';
export const FORGOT_PASSWORD_PATH = '/forgot-password';
export const FORGOT_PASSWORD_SUCCESS_PATH = '/forgot-password/success';
export const RESET_PASSWORD_PATH = '/reset-password';

export const WELCOME_PATH = '/welcome';

export const ADMIN_PATH = '/custom-admin';
export const ADMIN_PROJECT_PATH = `${ADMIN_PATH}/project-manager`;
export const ADMIN_PROJECT_PLANS_PATH = `${ADMIN_PROJECT_PATH}/project-plans`;
export const ADMIN_PROJECT_GOALS_PATH = `${ADMIN_PROJECT_PATH}/project-goals`;
export const ADMIN_PROJECT_ACTION_CATEGORIES_PATH = `${ADMIN_PROJECT_PATH}/action-categories`;
export const ADMIN_PROJECT_ACTION_ITEMS_PATH = `${ADMIN_PROJECT_PATH}/action-items`;
