const sharedSettings = {
  decision: { editable: true, type: 'decision-select', rules: [{ required: true }] },

  name: { editable: true, type: 'input' },
  image: { editable: true, type: 'picture' },
  link: { editable: true, type: 'input' },

  weighted_score: { editable: true, type: 'number', rules: [{ required: true }] },
  adjusted_score: { editable: true, type: 'number', rules: [{ required: true }] },
  second_stage_weighted_score: { editable: true, type: 'number', rules: [{ required: true }] },
  second_stage_adjusted_score: { editable: true, type: 'number', rules: [{ required: true }] },
  completed_criteria_percent: { editable: true, type: 'number', rules: [{ required: true }] },
  second_stage_completed_criteria_percent: { editable: true, type: 'number', rules: [{ required: true }] },

  notes: { editable: true, type: 'textarea' },

  highlighted: { editable: true, type: 'switch' },
  shortlist: { editable: true, type: 'switch' },
  finalist: { editable: true, type: 'switch' },

  verdict: { editable: true, type: 'textarea' },
}

export const settings = {
  id: { label: "ID" },
  ...sharedSettings,

  created_at: { editable: false, type: 'datetime', rules: [{ type: 'object' as const }] },
  updated_at: { editable: false, type: 'datetime', rules: [{ type: 'object' as const }] },  
};

export const initialSettings = {
  id: { hidden: true },
  ...sharedSettings,

  created_at: { hidden: true },
  updated_at: { hidden: true },
}

export const initialValues = {
  decision: '',
  name: '',
  image: '',
  link: '',
  weighted_score: 0,
  adjusted_score: 0,
  second_stage_weighted_score: 0,
  second_stage_adjusted_score: 0,
  completed_criteria_percent: 0,
  second_stage_completed_criteria_percent: 0,
  notes: '',
  highlighted: false,
  shortlist: false,
  finalist: false,
  verdict: '',
};
