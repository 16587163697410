import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Space } from 'antd';
import moment from 'moment';

import FilterableTable from 'components/Admin/ObjectTable';

import { fetchUserGoals } from 'Redux/Admin/GoalsManager/UserGoals/action';

import type { FilterConfig } from '@types';
import type { TableColumnsType } from 'antd';
import type { IUserGoal } from 'Redux/Admin/GoalsManager/UserGoals/type';

import rootReducer from 'Redux/root-reducer';

import { ADMIN_PATH } from "utils/routes";

type RootState = ReturnType<typeof rootReducer>;

const columns: TableColumnsType<IUserGoal> = [
  {
    title: 'ID',
    dataIndex: 'id',
    width: 280,
    render: (_, record) => (
      <Space size="middle">
        <Link to={`${ADMIN_PATH}/goals/journeys/user-goals/${record.id}`}>{record.id}</Link>
      </Space>
    ),
  },
  {
    title: 'Goal name',
    dataIndex: 'goal_name',
    width: 280,
  },
  {
    title: 'Parent Area',
    dataIndex: 'parent_area',
    width: 280,
    render: (_, record) => (
      <Space size="middle">
        <Link to={`${ADMIN_PATH}/goals/journeys/user-areas/${record.parent_area}`}>{record.parent_area}</Link>
      </Space>
    ),
  },
  
  {
    title: 'Created At',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (text) => moment(text).format("YYYY-MM-DD HH:mm:ss"),
    width: 180,
  },
  {
    title: 'Action',
    key: 'action',
    width: 160,
    fixed: 'right',
    render: (_, record) => (
      <Space size="middle">
        <Link to={`${ADMIN_PATH}/goals/journeys/user-actions?goal=${record.id}`}>View User Actions</Link>
      </Space>
    ),
  },
];

const UserGoals = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  // Extract query params
  const params = new URLSearchParams(location.search);

  const areaId = params.get('area') || '';
  const searchValue = params.get('search') || '';
  const pageValue = parseInt(params.get('page') || '1');
  const pageSizeValue = parseInt(params.get('size') || '50');

  // Redux state
  const { entities, ids, count, loading } = useSelector((state: RootState) => state.fetchUserGoals);

  // Fetch master plans whenever relevant query params change
  useEffect(() => {
    dispatch(
      fetchUserGoals({
        parent_area: areaId || undefined,
        search: searchValue || undefined,
        offset: (pageValue - 1) * pageSizeValue,
        limit: pageSizeValue,
      }, 
      () => {})
    );
  }, [areaId, searchValue, pageValue, pageSizeValue, dispatch]);

  // Build filter configs for this page
  const filterConfigs: FilterConfig[] = [
    {
      type: 'search',
      paramName: 'search',
      label: 'Search',
      placeholder: 'Search user name and area name...'
    },
    {
      type: 'area-select',
      paramName: 'area',
      placeholder: 'Select area...',
      label: 'Parent Area'
    },
  ];

  const dataItems = ids.map(id => entities[id]);

  return (
    <FilterableTable<IUserGoal>
      columns={columns}
      data={dataItems}
      loading={loading}
      totalItems={count}
      filters={filterConfigs}
      createLink={`${ADMIN_PATH}/goals/journeys/user-goals/new`}
    />
  );
};

export default UserGoals;
