export const ActionTypes = {
  REQUEST: "ADMIN_FETCH_ACTION_CATEGORYS_REQUEST",
  SUCCESS: "ADMIN_FETCH_ACTION_CATEGORYS_SUCCESS",
  FAILURE: "ADMIN_FETCH_ACTION_CATEGORYS_FAILURE",

  REQUEST_SINGLE: "ADMIN_FETCH_ACTION_CATEGORY_REQUEST",
  SUCCESS_SINGLE: "ADMIN_FETCH_ACTION_CATEGORY_SUCCESS",
  FAILURE_SINGLE: "ADMIN_FETCH_ACTION_CATEGORY_FAILURE",

  UPDATE_REQUEST: "ADMIN_UPDATE_ACTION_CATEGORY_REQUEST",
  UPDATE_SUCCESS: "ADMIN_UPDATE_ACTION_CATEGORY_SUCCESS",
  UPDATE_FAILURE: "ADMIN_UPDATE_ACTION_CATEGORY_FAILURE",

  CREATE_REQUEST: "ADMIN_CREATE_ACTION_CATEGORY_REQUEST",
  CREATE_SUCCESS: "ADMIN_CREATE_ACTION_CATEGORY_SUCCESS",
  CREATE_FAILURE: "ADMIN_CREATE_ACTION_CATEGORY_FAILURE",

  DELETE_REQUEST: "ADMIN_DELETE_ACTION_CATEGORY_REQUEST",
  DELETE_SUCCESS: "ADMIN_DELETE_ACTION_CATEGORY_SUCCESS",
  DELETE_FAILURE: "ADMIN_DELETE_ACTION_CATEGORY_FAILURE",
}

export interface IActionCategory {
  id: number;
  start_date_grace_time_left: boolean;
  is_start_date_locked: boolean;
  end_date_grace_time_left: boolean;
  is_end_date_locked: boolean;
  created_at: string;
  updated_at: string;
  plan_progress: string;
  category_progress: string;
  weight: string;
  start: string | null;
  current: string | null;
  target: string | null;
  start_change_timestamp: string | null;
  start_line: string;
  dead_line: string | null;
  start_line_change_timestamp: string | null;
  end_line_change_timestamp: string | null;
  name: string;
  duration: string;
  order_id: number;
  project_plan: number;
}

interface NormalizedEntities {
  [key: string]: IActionCategory;
}

export interface IState {
  entities: NormalizedEntities;
  ids: string[];
  count: number;
  loading: boolean;
  error: any;
}
