export const ActionTypes = {
  REQUEST: "ADMIN_FETCH_DOMAINS_REQUEST",
  SUCCESS: "ADMIN_FETCH_DOMAINS_SUCCESS",
  FAILURE: "ADMIN_FETCH_DOMAINS_FAILURE",

  REQUEST_SINGLE: "ADMIN_FETCH_DOMAIN_REQUEST",
  SUCCESS_SINGLE: "ADMIN_FETCH_DOMAIN_SUCCESS",
  FAILURE_SINGLE: "ADMIN_FETCH_DOMAIN_FAILURE",

  UPDATE_REQUEST: "ADMIN_UPDATE_DOMAIN_REQUEST",
  UPDATE_SUCCESS: "ADMIN_UPDATE_DOMAIN_SUCCESS",
  UPDATE_FAILURE: "ADMIN_UPDATE_DOMAIN_FAILURE",

  DELETE_REQUEST: "ADMIN_DELETE_DOMAIN_REQUEST",
  DELETE_SUCCESS: "ADMIN_DELETE_DOMAIN_SUCCESS",
  DELETE_FAILURE: "ADMIN_DELETE_DOMAIN_FAILURE",
}

export interface IDomain {
  id: string;
  created_at: string,
  updated_at: string,
  name: string,
  order: number,
  description: string,
  icon: string | null,
  code: string,
  type: string,
  visible: boolean,
  arootah_only: boolean,
  legacy_domain: string | null
}

interface NormalizedEntities {
  [key: string]: IDomain;
}

export interface IState {
  entities: NormalizedEntities;
  ids: string[];
  count: number;
  loading: boolean;
}
