import { normalize, schema } from 'normalizr';
import { IState, ActionTypes } from './type';

const projectPlanSchema = new schema.Entity('projectplans');
const projectPlanListSchema = [projectPlanSchema];

const initial: IState = {
  entities: {},
  ids: [],
  count: 0,
  loading: false,
  error: null
};

const reducer = (state: IState = initial, action: any) => {
  switch (action.type) {
    case ActionTypes.REQUEST:
      return {
        ...state,
        loading: true
      };

    case ActionTypes.SUCCESS: {
      const normalizedData = normalize(action.payload.results, projectPlanListSchema);

      return {
        ...state,
        entities: {
          ...state.entities,
          ...normalizedData.entities.projectplans,
        },
        ids: normalizedData.result as string[],
        count: action.payload.count as number,
        loading: false
      };
    }

    case ActionTypes.SUCCESS_SINGLE: {
      const projectPlan = action.payload;
    
      return {
        ...state,
        entities: {
          ...state.entities,
          [projectPlan.id]: projectPlan
        },
        loading: false
      };
    }

    case ActionTypes.CREATE_SUCCESS: {
      const projectPlan = action.payload;
    
      return {
        ...state,
        entities: {
          ...state.entities,
          [projectPlan.id]: projectPlan
        },
        loading: false
      };
    }

    case ActionTypes.UPDATE_SUCCESS: {
      const projectPlan = action.payload;
    
      return {
        ...state,
        entities: {
          ...state.entities,
          [projectPlan.id]: projectPlan
        },
        loading: false
      };
    }

    case ActionTypes.DELETE_SUCCESS: {
      const { id } = action.payload;
    
      const { [id]: deletedItem, ...remainingEntities } = state.entities;
    
      return {
        ...state,
        entities: remainingEntities,
        ids: state.ids.filter(existingId => existingId !== id),
        loading: false
      };
    }

    case ActionTypes.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}

export default reducer;
