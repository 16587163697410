import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import { FC, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, InputNumber, Switch, Button, Space, Upload, TimePicker, DatePicker, Select } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import {
  UserSelect,
  DomainSelect,
  MasterplanSelect,
  AreaSelect,
  GoalSelect,
  ProjectUserSelect,
  ProjectPlanSelect,
  DecisionSelect,
  OptionSelect,
  CriterionSelect,
  OptionCriterionSelect
} from '../Selectors';

dayjs.extend(utc);
dayjs.extend(timezone);

const { TextArea } = Input;

interface FieldSettings {
  type?: string; // Type of the field (e.g. input, switch, select)
  editable?: boolean; // Determines if the field is editable or read-only
  hidden?: boolean; // Determines if the field is hidden or visible
  label?: string; // Custom label for the field
  link?: string;
  rules?: Array<any>; // Validation rules for the field
}

interface ObjectFormProps {
  data: Record<string, any>; // The raw object data
  errorData?: Record<string, any>; // Error data for form fields
  isNew?: boolean; // Flag to determine if the form is for a new object
  isReadOnly?: boolean; // Flag to determine if the form is read-only
  settings?: Record<string, FieldSettings>; // Optional settings for customizing each field
  loading?: boolean; // Loading state for the form
  onFinish?: (values: any) => void; // Callback for form submission
}

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const parseLabel = (key: string): string => {
  return key
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

const renderField = (fieldType: string, editable: boolean, value: any, options: any, link?: string, loading?: boolean) => {
  if (!editable) {
    if (fieldType === 'datetime' && value) {
      return <span className="ant-form-text">{dayjs(value).format('YYYY-MM-DD HH:mm:ss')}</span>;
    }
    if (fieldType === 'link' && link && value) {
      return <Link to={link.replace('{value}', value)}>{value}</Link>;
    }
    return <span className="ant-form-text">{value}</span>;
  }

  switch (fieldType) {
    case 'input':
      return <Input />;
    case 'number':
      return <InputNumber />;
    case 'textarea':
      return <TextArea />;
    case 'time': 
      return <TimePicker format="HH:mm:ss" />;
    case 'datetime': 
      return <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />;
    case 'switch':
      return <Switch />;
    case 'user-select':
      return <UserSelect value={value} />;
    case 'project-user-select':
      return <ProjectUserSelect value={value} />;
    case 'subdomain-select':
      return <DomainSelect value={value} />;
    case 'masterplan-select':
      return <MasterplanSelect value={value} />;
    case 'area-select':
      return <AreaSelect value={value} />;
    case 'goal-select':
      return <GoalSelect value={value} />;
    case 'project-plan-select':
      return <ProjectPlanSelect value={value} />;
    case 'decision-select':
      return <DecisionSelect value={value} />;
    case 'option-select':
      return <OptionSelect value={value} />;
    case 'criterion-select':
      return <CriterionSelect value={value} />;
    case 'option-criterion-select':
      return <OptionCriterionSelect value={value} />;
    case 'select':
      return (
        <Select loading={loading}>
          {options.map((option: any) => (
            <Select.Option key={option.value} value={option.value}>
              {option.label || (option.value ? option.value.charAt(0).toUpperCase() + option.value.slice(1) : "--")}
            </Select.Option>
          ))}
        </Select>
      );
    default:
      return <Input />; // Fallback to Input if no specific type is provided
  }
};

const isPrimitive = (value: any) => {
  return value !== Object(value); // Returns true for strings, numbers, booleans, etc.
};

const filterPrimitiveFields = (data: Record<string, any> | null | undefined) => {
  // Check if data is null or undefined and return an empty object in that case
  if (!data || typeof data !== 'object') {
    return {};
  }

  return Object.keys(data).reduce((acc, key) => {
    if (isPrimitive(data[key])) {
      acc[key] = data[key];
    }
    return acc;
  }, {} as Record<string, any>);
};

const ObjectForm: FC<ObjectFormProps> = ({ data, errorData, settings = {}, isNew, loading, onFinish }) => {
  const [form] = Form.useForm();
  const initialDataRef = useRef<Record<string, any>>({}); // To store the initial data for comparison

  useEffect(() => {
    const filteredData = filterPrimitiveFields(data);
  
    // Iterate over settings to check if any field is of type 'picture'
    Object.keys(settings).forEach((key) => {
      const fieldSettings = settings[key];
      
      if (fieldSettings.type === 'picture') {
        if (data[key]) {
          filteredData[key] = [{
            uid: '-1',
            name: `${key}.png`,
            status: 'done',
            url: data[key]
          }];
        } else {
          filteredData[key] = [];
        }
      }
      else if (fieldSettings.type === 'time' && data[key]) {
        filteredData[key] = dayjs(data[key], "HH:mm:ss");
      }
      else if (fieldSettings.type === 'datetime' && data[key]) {
        filteredData[key] = dayjs(data[key]);
      }
    });

    // Store the initial data for future comparison
    initialDataRef.current = { ...filteredData };
  
    form.setFieldsValue(filteredData);
  }, [data, form, settings]);

  useEffect(() => {
    if (errorData) {
      const fields = Object.keys(errorData).map(key => ({
        name: key,
        errors: [errorData[key]],
      }));
      form.setFields(fields);
    }
  }, [form, errorData]);

  const renderFormItem = (key: string, value: any) => {
    if (!isPrimitive(value)) {
      return null;
    }

    const fieldSettings = settings[key] || {};
    const label = fieldSettings.label || parseLabel(key);
    
    const editable = fieldSettings.editable ?? false;
    const hidden = fieldSettings.hidden ?? false;
    const fieldType = fieldSettings.type || 'input'; // Default to input if type is not specified
    const link = fieldSettings.link;
    const options = fieldSettings.rules?.[0]?.options || [];
    const loading = fieldSettings.rules?.[0]?.loading || false;

    if (hidden)
      return null;

    if (fieldType === 'picture') {
      return (
        <Form.Item key={key} name={key} label={label} valuePropName="fileList" getValueFromEvent={normFile}>
          <Upload listType="picture-card" maxCount={1}>
            <button style={{ border: 0, background: 'none' }} type="button">
              <UploadOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </button>
          </Upload>
        </Form.Item>
      );
    }
  
    return (
      <Form.Item key={key} name={key} label={label} rules={fieldSettings.rules || []}>
        {renderField(fieldType, editable, value, options, link, loading)}
      </Form.Item>
    );
  };

  // Helper function to combine and order fields based on settings and data keys
  const getOrderedFields = () => {
    const settingsKeys = Object.keys(settings);
    const dataKeys = Object.keys(data);

    // Create an ordered array by prioritizing settings keys, then appending remaining data keys
    const orderedKeys = [
      ...settingsKeys,
      ...dataKeys.filter((key) => !settingsKeys.includes(key)),
    ];

    return orderedKeys;
  };

  // Helper function to compare form values and return only modified fields
  const getModifiedFields = (values: Record<string, any>) => {
    const modifiedFields: Record<string, any> = {};
  
    Object.keys(values).forEach((key) => {
      const value = values[key];

      if (value && typeof value === 'object' && value.$isDayjsObject) {
        // Compare dayjs date objects by date
        if (!initialDataRef.current[key] || !initialDataRef.current[key].isSame(value, 'day')) {
          modifiedFields[key] = value;
        }
      } else if (JSON.stringify(value) !== JSON.stringify(initialDataRef.current[key])) {
        modifiedFields[key] = value; // Only include fields that have changed
      }
    });
  
    return modifiedFields;
  };

  const extractValues = (obj: any) => {
    const result: any = {};

    for (const key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null && !obj[key].$isDayjsObject) {
        if ('value' in obj[key]) {
          result[key] = obj[key].value;
        }
        else {
          result[key] = extractValues(obj[key]);
        }
      } else {
        result[key] = obj[key];
      }
    }
    return result;
  };

  // Handle form submission
  const handleFinish = (values: any) => {
    const processedValues = extractValues(values);

    if (!onFinish)
      return;

    if (isNew) {
      onFinish(processedValues); 
    }
    else {
      const modifiedFields = getModifiedFields(processedValues);
      onFinish(modifiedFields); // Pass only modified fields to the callback
    }
  };

  /* const onReset = () => {
    form.resetFields();
  }; */

  return (
    <Form form={form} {...layout} onFinish={handleFinish} scrollToFirstError style={{ maxWidth: 600 }}>
      {getOrderedFields().map((key) => renderFormItem(key, data[key]))}

      <Form.Item {...tailLayout}>
        <Space align='end'>
          {/* <Button htmlType="button" onClick={onReset}>
            Reset
          </Button> */}

          {onFinish ? (
            <Button type="primary" disabled={loading} htmlType="submit">
              {loading ? 'Saving...' : 'Save'}
            </Button>
          ) : null}
        </Space>
      </Form.Item>
    </Form>
  );
};

export default ObjectForm;
