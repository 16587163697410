const sharedSettings = {
  category_name: { editable: true, type: 'input' },
  goal_name: { editable: true, type: 'input' },

  priority: { editable: true, type: 'number' },
  weight: { editable: true, type: 'number', rules: [{ required: true }] },

  measurement: { editable: true, type: 'input' },
  measurement_metric_name: { editable: true, type: 'input' },
  measurement_start_value: { editable: true, type: 'number' },
  measurement_current_value: { editable: true, type: 'number' },
  measurement_target_value: { editable: true, type: 'number' },

  frequency: { editable: true, type: 'select', rules: [{ required: true, options: [
    { value: 'Daily' },
    { value: 'Weekly' },
    { value: 'Monthly' },
    { value: 'Quarterly' },
    { value: 'Yearly' },
  ] }] },
  gap_value: { editable: true, type: 'number' },
  gap_percentage: { editable: true, type: 'number' },

  pleasure: { editable: true, type: 'input' },
  pain: { editable: true, type: 'input' },

  startline: { editable: true, type: 'datetime', rules: [{ type: 'object' as const }] },
  deadline: { editable: true, type: 'datetime', rules: [{ type: 'object' as const }] },

  habit: { editable: true, type: 'switch', label: "Is Habit" },
  accountability: { editable: true, type: 'input' },

  is_private: { editable: true, type: 'switch' },
  completed: { editable: true, type: 'switch', label: "Is Completed" },
  is_custom: { editable: true, type: 'switch', label: "Is Completed" },

  leverage_score_completed: { editable: true, type: 'switch', label: "Is Leverage Score Completed" },
  leverage_score: { editable: true, type: 'number', rules: [{ required: true }] },

  user: { editable: true, type: 'user-select', rules: [{ required: true }] },
  parent_area: { editable: true, type: 'area-select', label: "Area", rules: [{ required: true }] },
}

export const settings = {
  id: { label: "ID" },

  ...sharedSettings,

  created_at: { editable: true, type: 'datetime', rules: [{ type: 'object' as const }] },
  updated_at: { editable: true, type: 'datetime', rules: [{ type: 'object' as const }] },
};

export const initialSettings = {
  id: { hidden: true },

  ...sharedSettings,
  
  created_at: { hidden: true },
  updated_at: { hidden: true },
}

export const initialValues = {
  category_name: "",
  goal_name: "",

  priority: 0,
  weight: 0,

  measurement: "",
  measurement_metric_name: "",
  measurement_start_value: 0,
  measurement_current_value: 0,
  measurement_target_value: 0,

  frequency: "Weekly",
  gap_value: 0,
  gap_percentage: 0,

  pleasure: "",
  pain: "",

  startline: null,
  deadline: null,

  habit: false,
  accountability: "",

  is_private: false,
  completed: false,
  is_custom: false,

  leverage_score_completed: false,
  leverage_score: 0,

  user: null,
  parent_area: null
};
