const sharedSettings = {
  name: { editable: true, type: 'input' },
  weight: { editable: true, type: 'input' },
  color: { editable: true, type: 'input' },

  is_custom: { editable: true, type: 'switch' },
  is_locked: { editable: true, type: 'switch' },
  is_private: { editable: true, type: 'switch' },

  created_at: { editable: true, type: 'datetime', rules: [{ type: 'object' as const }] },
  updated_at: { editable: true, type: 'datetime', rules: [{ type: 'object' as const }] },

  user: { editable: true, type: 'user-select', rules: [{ required: true }] },
  parent_masterplan: { editable: true, type: 'masterplan-select', label: "Masterplan", rules: [{ required: true }] },
}


export const settings = {
  id: { label: "ID" },
  ...sharedSettings
};

export const initialSettings = {
  ...sharedSettings,
  id: { hidden: true },
  created_at: { hidden: true },
  updated_at: { hidden: true },
}

export const initialValues = {
  id: "",
  name: "",
  weight: 0,
  color: "",

  is_custom: false,
  is_locked: false,
  is_private: false,

  user: null,
  parent_masterplan: null,
};
