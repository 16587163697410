import { useEffect } from 'react';
import { Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from "react-router-dom";
import moment from 'moment';

import FilterableTable from 'components/Admin/ObjectTable';

import { fetchFocusedPriorities } from 'Redux/Admin/TaskManager/FocusedPriorities/action';

import rootReducer from 'Redux/root-reducer';

import type { TableColumnsType } from 'antd';
import type { FilterConfig } from '@types';
import type { IFocusedPriority } from 'Redux/Admin/TaskManager/FocusedPriorities/type';

import { ADMIN_PATH } from "utils/routes";

type RootState = ReturnType<typeof rootReducer>;

const columns: TableColumnsType<IFocusedPriority> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 120,
    render: (_, record) => (
      <Space size="middle">
        <Link to={`${ADMIN_PATH}/task-manager/focused-priorities/${record.id}`}>{record.id}</Link>
      </Space>
    ),
  },
  {
    title: 'Priority',
    dataIndex: 'priority',
    key: 'priority',
    width: 80,
  },
  {
    title: 'Week Starting',
    dataIndex: 'week_starting',
    key: 'week_starting',
    render: (text) => moment(text).format("YYYY-MM-DD"),
    width: 120,
  },
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
    width: 120,
  },
];

const FocusedPriorities = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const user = params.get('user');
  const searchValue = params.get('search');
  const pageValue = parseInt(params.get('page') || '1');

  const { entities, ids, count, loading } = useSelector((state: RootState) => state.fetchFocusedPriorities);

  useEffect(() => {
    dispatch(fetchFocusedPriorities({ user, search: searchValue, offset: (pageValue - 1) * 50, limit: 50 }, () => {}));
  }, [user, searchValue, pageValue, dispatch]);

  // Build filter configs for this page
  const filterConfigs: FilterConfig[] = [
    {
      type: 'search',
      paramName: 'search',
      label: 'Search',
      placeholder: 'Search...'
    },
    {
      type: 'user-select',
      paramName: 'user',
      placeholder: 'Select user...',
      label: 'User'
    },
  ];

  const dataItems = ids.map(id => entities[id]);

  return (
    <FilterableTable<IFocusedPriority>
      columns={columns}
      data={dataItems}
      loading={loading}
      totalItems={count}
      filters={filterConfigs}
    />
  );
}

export default FocusedPriorities;
