import Notify from "utils/notify";

import {
  adminFetchOptionCriteria,
  adminFetchOptionCriterion,
  adminCreateOptionCriterion,
  adminUpdateOptionCriterion,
  adminDeleteOptionCriterion
} from "api";

import { ActionTypes, IOptionCriterion } from "./type";

export const fetchOptionCriteria = (payload: any, callback?: () => any) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.REQUEST });

    const { data } = await adminFetchOptionCriteria(payload);

    if (Array.isArray(data.results)) {
      dispatch({
        type: ActionTypes.SUCCESS,
        payload: data
      });
    }
    else {
      dispatch({
        type: ActionTypes.FAILURE,
        payload: data
      });

      Notify.error(data, true);
    }

    if (callback)
      callback();
  } catch (error) {
    console.error(error);

    dispatch({
      type: ActionTypes.FAILURE,
      payload: error
    });

    Notify.error(error, true);
  }
};

export const fetchOptionCriterion = (payload: any, callback?: () => any) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.REQUEST });

    const { data } = await adminFetchOptionCriterion(payload);

    dispatch({
      type: ActionTypes.SUCCESS_SINGLE,
      payload: data
    });

    if (callback)
      callback();
  } catch (error) {
    console.error(error);

    dispatch({
      type: ActionTypes.FAILURE,
      payload: error
    });

    Notify.error(error, true);
  }
};

export const createOptionCriterion = (payload: any, callback?: (data: IOptionCriterion) => any) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.REQUEST });

    const { data } = await adminCreateOptionCriterion(payload);

    dispatch({
      type: ActionTypes.CREATE_SUCCESS,
      payload: data
    });

    Notify.success('Option-Criterion created successfully!');

    if (callback)
      callback(data);
  } catch (error: any) {
    console.error(error);

    dispatch({
      type: ActionTypes.FAILURE,
      payload: error?.response?.data
    });

    Notify.error(error, true);
  }
};

export const updateOptionCriterion = (payload: any, id: string, callback?: () => any) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.REQUEST });

    const { data } = await adminUpdateOptionCriterion(payload, id);

    dispatch({
      type: ActionTypes.UPDATE_SUCCESS,
      payload: data
    });

    Notify.success('Option-Criterion updated successfully!');

    if (callback)
      callback();
  } catch (error: any) {
    console.error(error);

    dispatch({
      type: ActionTypes.FAILURE,
      payload: error?.response?.data
    });

    Notify.error(error, true);
  }
};

export const deleteOptionCriterion = (id: string, callback?: () => any) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.REQUEST });

    await adminDeleteOptionCriterion(id);

    dispatch({
      type: ActionTypes.DELETE_SUCCESS,
      payload: id
    });

    Notify.success('Option-Criterion deleted successfully!');

    if (callback)
      callback();
  } catch (error) {
    console.error(error);

    dispatch({
      type: ActionTypes.FAILURE,
      payload: error
    });

    Notify.error(error, true);
  }
};
