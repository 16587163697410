export const ActionTypes = {
  REQUEST: "ADMIN_FETCH_PROJECT_USERS_REQUEST",
  SUCCESS: "ADMIN_FETCH_PROJECT_USERS_SUCCESS",
  FAILURE: "ADMIN_FETCH_PROJECT_USERS_FAILURE",
}
export interface IProjectUser {
  id: string;
  password: string;
  last_login: string | null;
  is_superuser: boolean;
  first_name: string;
  last_name: string;
  is_staff: boolean;
  is_active: boolean;
  date_joined: string;
  username: string;
  public_id: string;
  email: string;
  type: string;
  name: string;
  is_guest: boolean;
  ip: string | null;
  tokens: number;
  activation_key: string;
  otp: string;
  terms_accepted: boolean;
  tutorial_progress: Record<string, unknown>;
  progress_updated_at: string | null;
  groups: any[];
  user_permissions: any[];
  coaches: any[];
}

interface NormalizedEntities {
  [key: string]: IProjectUser;
}

export interface IState {
  entities: NormalizedEntities;
  ids: string[];
  count: number;
  loading: boolean;
}
