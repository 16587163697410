import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { Flex, Typography, Button, ConfigProvider, Layout, theme } from 'antd';
import { SunFilled, MoonFilled, LogoutOutlined } from '@ant-design/icons';

import Sidebar from 'components/Admin/Sidebar';

// @ts-ignore
import { Logout } from "Redux/Logout/action";

import { SIGN_IN_PATH } from 'utils/routes';

const { Header, Content } = Layout;
const { Title } = Typography;

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { defaultAlgorithm, darkAlgorithm } = theme;

  const [isDarkMode, setIsDarkMode] = useState(false);
  
  const handleClick = () => {
    setIsDarkMode((previousValue) => !previousValue);
  };

  const handleLogoutClick = () => {
    dispatch(Logout(() => {
      navigate(SIGN_IN_PATH, { replace: true });
    }));
  }

  return (
    <ConfigProvider
      theme={{
        algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
      }}>
      <Layout>
        <Header style={{ position: 'sticky', top: 0, zIndex: 9, display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0 16px' }}>
          <Title level={4} style={{ color: '#fff', margin: 0 }}>Admin Dashboard</Title>

          <Flex align="center" gap="8px">
            <Button
              shape="circle"
              variant="text"
              icon={isDarkMode ? <SunFilled /> : <MoonFilled />}
              title={isDarkMode ? 'Switch to light mode' : 'Switch to dark mode'}
              onClick={handleClick}
            />

            <Button
              shape="circle"
              variant="text"
              icon={<LogoutOutlined />}
              title={'Logout'}
              onClick={handleLogoutClick}
            />
          </Flex>
        </Header>

        <Content>
          <Layout
            style={{ minHeight: `calc(100vh - 64px)` }}
          >
            <Sidebar />

            <Content style={{ padding: '24px', minHeight: 280 }}>
              <Outlet />
            </Content>
          </Layout>
        </Content>
      </Layout>
    </ConfigProvider>
  );
}

export default AdminDashboard;
