import { normalize, schema } from 'normalizr';
import { IState, ActionTypes } from './type';

const decisionSchema = new schema.Entity('decisions');
const decisionListSchema = [decisionSchema];

const initial: IState = {
  entities: {},
  ids: [],
  count: 0,
  loading: false,
  error: null
};

const reducer = (state: IState = initial, action: any) => {
  switch (action.type) {
    case ActionTypes.REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };

    case ActionTypes.SUCCESS: {
      const data = action.payload.results
        .map((decision: any) => ({
          ...decision,
          user_email: decision.user.email,
          user: decision.user.id,
        }));

      const normalizedData = normalize(data, decisionListSchema);

      return {
        ...state,
        entities: {
          ...state.entities,
          ...normalizedData.entities.decisions,
        },
        ids: normalizedData.result as string[],
        count: action.payload.count as number,
        loading: false
      };
    }

    case ActionTypes.SUCCESS_SINGLE: {
      const decision = action.payload;

      const processedData = {
        ...decision,
        user_email: decision.user.email,
        user: decision.user.id
      }
    
      return {
        ...state,
        entities: {
          ...state.entities,
          [decision.id]: processedData
        },
        loading: false
      };
    }

    case ActionTypes.CREATE_SUCCESS: {
      const decision = action.payload;
    
      return {
        ...state,
        entities: {
          ...state.entities,
          [decision.id]: decision
        },
        loading: false
      };
    }

    case ActionTypes.UPDATE_SUCCESS: {
      const decision = action.payload;
    
      return {
        ...state,
        entities: {
          ...state.entities,
          [decision.id]: decision
        },
        loading: false
      };
    }

    case ActionTypes.DELETE_SUCCESS: {
      const { id } = action.payload;
    
      const { [id]: deletedItem, ...remainingEntities } = state.entities;
    
      return {
        ...state,
        entities: remainingEntities,
        ids: state.ids.filter(existingId => existingId !== id),
        loading: false
      };
    }

    case ActionTypes.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}

export default reducer;
